(function () {
    'use strict';

    angular
        .module('awizApp')
        .config(function (nyaBsConfigProvider) {
            nyaBsConfigProvider.setLocalizedText("pl", {
                defaultNoneSelection: '-- wybierz --',
                noSearchResult: 'brak rekordów',
                numberItemSelected: 'Zaznaczono - %d ',
                selectAll: 'Zaznacz wszystko',
                deselectAll: 'Odznacz wszystko'
            });
            nyaBsConfigProvider.setLocalizedText("en", {
                defaultNoneSelection: '-- select --',
                noSearchResult: 'no records',
                numberItemSelected: 'Selected - %d ',
                selectAll: 'Select all',
                deselectAll: 'Deselect all'
            });
        })
        .config(function (amoMultiselectConfig) {
            amoMultiselectConfig.deselectAllText = "Odznacz wszystko";
            amoMultiselectConfig.filterText = "Szukaj...";
            amoMultiselectConfig.selectAllText = "Zaznacz wszystko";
            amoMultiselectConfig.selectText = "wybierz...";
            amoMultiselectConfig.selectedSuffixSingularText = " poz. ";
            amoMultiselectConfig.selectedSuffixText = " poz. ";
        })
        .factory('JhiLanguageService', function ($q, $http, $translate, LANGUAGES) {
            return {
                getAll: getAll,
                getCurrent: getCurrent
            };

            function getAll() {
                var deferred = $q.defer();
                deferred.resolve(LANGUAGES);
                return deferred.promise;
            }

            function getCurrent() {
                var deferred = $q.defer();
                var language = $translate.storage().get('NG_TRANSLATE_LANG_KEY');

                deferred.resolve(language);

                return deferred.promise;
            }

        });

})();
