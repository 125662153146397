(function () {
    'use strict';

    angular
        .module('awizApp')
        .controller('SlotDialogController', function ($scope, $uibModalInstance, Slot, item) {
            var vm = this;
            vm.item = item;

            function onSaveSuccess(result) {
                $scope.$emit('slot:updated', result);
                $uibModalInstance.close(result);
            }
            vm.save = function () {
                var tmp = angular.copy(vm.item);

                if (tmp.id !== null) {
                    return Slot.update(tmp, onSaveSuccess).$promise;
                } else {
                    return Slot.save(tmp, onSaveSuccess).$promise;
                }
            };

            vm.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            };

            vm.onWarehouseSelect = function (warehouse) {
                vm.item.warehouseId = warehouse.id;
            };

        });

})();
