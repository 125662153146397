(function() {
    'use strict';

    angular
        .module('awizApp')
        .component('tableNoData', {
            template: '<div class="table-no-data" ng-if="$ctrl.items && $ctrl.items.length == 0 && !$ctrl.isLoading"><span translate="global.no-records"></span></div>',
            bindings: {
                items: '<'
            },
            controller: function ($transitions) {

                this.isLoading = false;

                var onBefore = $transitions.onBefore({}, function (transition) {
                    if (transition.from() === transition.to()) {
                        this.isLoading = true;
                    }
                }.bind(this));

                var onFinish = $transitions.onFinish({}, function (transition) {
                    if (transition.from() === transition.to()) {
                        this.isLoading = false;
                    }
                }.bind(this));

                var onError = $transitions.onError({}, function (transition) {
                    if (transition.from() === transition.to()) {
                        this.isLoading = false;
                    }
                }.bind(this));

                this.$onDestroy = function () {
                    onBefore();
                    onFinish();
                    onError();
                };

            }
        });

})();
