(function() {
    'use strict';

    angular
        .module('awizApp')
        .config(function (AlertServiceProvider) {
            // set below to true to make alerts look like toast
            AlertServiceProvider.showAsToast(true);
        });

})();
