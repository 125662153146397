(function() {
    'use strict';

    angular
        .module('awizApp')
        .factory('Sessions', function ($resource) {
            return $resource('api/account/sessions/:series', {}, {
                'getAll': { method: 'GET', isArray: true}
            });
        });

})();
