(function() {
    'use strict';

    angular
        .module('awizApp')
        .factory('DateRangePickerConfig', function ($translate) {
            var def = {
                ranges: {},
                locale: {
                    applyLabel: $translate.instant("entity.action.save"),
                    cancelLabel: $translate.instant("entity.action.clear"),
                    fromLabel: $translate.instant("select.from"),
                    toLabel: $translate.instant("select.to"),
                    customRangeLabel: $translate.instant("select.custom-range"),
                    firstDay: 1
                },
                format: 'YYYY-MM-DD'
            };

            var TODAY = $translate.instant("select.today");
            var YESTERDAY = $translate.instant("select.yesterday");
            var THIS_WEEK = $translate.instant("select.this-week");
            var LAST_WEEK = $translate.instant("select.last-week");
            var LAST_7D = $translate.instant("select.last-7D");
            var LAST_30D = $translate.instant("select.last-30D");
            var THIS_MONTH = $translate.instant("select.this-month");
            var LAST_MONTH = $translate.instant("select.last-month");
            var THIS_QUARTER = $translate.instant("select.this-quarter");
            var LAST_QUARTER = $translate.instant("select.last-quarter");

            def.ranges[TODAY] = [ moment(), moment() ];
            def.ranges[YESTERDAY] = [ moment().subtract(1, 'days'), moment().subtract(1, 'days') ];
            def.ranges[THIS_WEEK] = [ moment().startOf('week'), moment().endOf('week') ];
            def.ranges[LAST_WEEK] = [ moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week') ];
            def.ranges[LAST_7D] = [ moment().subtract(6, 'days'), moment() ];
            def.ranges[LAST_30D] = [ moment().subtract(29, 'days'), moment() ];
            def.ranges[THIS_MONTH] = [ moment().startOf('month'), moment().endOf('month') ];
            def.ranges[LAST_MONTH] = [ moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month') ];
            def.ranges[THIS_QUARTER] = [ moment().startOf('quarter'), moment().endOf('quarter') ];
            def.ranges[LAST_QUARTER] = [ moment().subtract(1, "Q").startOf('quarter'), moment().subtract(1, "Q").endOf('quarter') ];

            return {
                get: get
            };

            function get(override) {
                var result = angular.copy(def);
                angular.extend(result, override);
                return result;
            }

        });

})();
