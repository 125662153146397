(function() {
    'use strict';

    angular
        .module('awizApp')
        .directive('fieldSearch', function () {
            return {
                template: '<div class="input-group input-group-sm">\n' +
                    '                <span class="input-group-addon" translate-attr="{ title: \'entity.action.search\' }"><i class="fas fa-search fa-fw"></i></span>\n' +
                    '                <input ng-model="modelValue" id="searchQuery" type="text" placeholder="{{ \'entity.action.search-placeholder\' | translate }}" ng-keyup="$event.keyCode == 13 && onEnter()" class="form-control">\n' +
                    '                <div class="input-group-btn">\n' +
                    '                    <button type="button" class="btn btn-default" ng-click="clear()" ng-disabled="!modelValue">\n' +
                    '                        <i class="far fa-trash-alt trash-clear"></i>\n' +
                    '                    </button>\n' +
                    '                    <button class="btn btn-default btn-success" type="button" ng-click="click()">\n' +
                    '                        <i class="fas fa-paper-plane"></i>\n' +
                    '                    </button>\n' +
                    '                </div>\n' +
                    '            </div>',
                scope: {
                },
                require: '^ngModel',
                link: function(scope, $element, $attrs, ngModelCtrl){

                    scope.clear = function () {
                        delete scope.modelValue;
                        scope.setValue();
                    };

                    scope.click = function () {
                        if(scope.buttonType !== 'submit')
                            scope.setValue();
                    };

                    scope.setValue = function(){
                        ngModelCtrl.$setViewValue(scope.modelValue);
                        ngModelCtrl.$validate();
                    };

                    ngModelCtrl.$render = function() {
                        scope.modelValue = ngModelCtrl.$viewValue;
                    };

                    scope.onEnter = function () {
                        scope.setValue();
                    };
                }
            };
        });
})();
