(function() {
    'use strict';

    angular
        .module('awizApp')
        .controller('NavbarController', function ($state, Auth, Principal, ENV, AlertService) {
            var vm = this;

            vm.isNavbarCollapsed = true;
            vm.isAuthenticated = Principal.isAuthenticated;
            vm.hasSlots = Principal.hasSlots;
            vm.inProduction = ENV === 'prod';
            vm.toggleNavbar = toggleNavbar;
            vm.collapseNavbar = collapseNavbar;
            vm.$state = $state;

            vm.logout = function () {
                collapseNavbar();
                Auth.logout();
                $state.go('login');
            };
            vm.exitUser = function () {
                collapseNavbar();
                Auth.exitUser().then(function (result) {
                    AlertService.success("user.events.privileges-restored");
                    $state.go('home');
                });
            };

            function toggleNavbar () {
                vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
            }

            function collapseNavbar () {
                vm.isNavbarCollapsed = true;
            }
        });

})();
