(function() {
    'use strict';

    angular
        .module('awizApp')
        .directive('currentDatetime', function ($interval) {
            return {
                scope: {
                    timezone: "="
                },
                restrict: 'AE',
                template: '<span ng-if="timezone">{{currentTime | amTimezone:timezone | amDateFormat:format}}</span>',
                link: function(scope) {
                    scope.currentTime = moment();

                    var currentTimeInterval = $interval(function () {
                        scope.currentTime = moment();
                    }, 1000);

                    scope.$on('$destroy', function () {
                        $interval.cancel(currentTimeInterval);
                    });
                }
            };
        });
})();
