(function() {
    'use strict';

    angular
        .module('awizApp')
        .config(function ($stateProvider) {
            $stateProvider.state('jhi-configuration', {
                parent: 'admin',
                url: '/configuration',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'configuration.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/admin/configuration/configuration.html',
                        controller: 'JhiConfigurationController',
                        controllerAs: 'vm'
                    }
                }
            });
        });

})();
