(function() {
    'use strict';

    angular
        .module('awizApp')
        .factory('Activate', function ($resource) {
            return $resource('api/activate', {}, {
                'get': {method: 'GET', params: {}, isArray: false}
            });
        });

})();
