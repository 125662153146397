(function() {
    'use strict';

    angular
        .module('awizApp')
        .controller('ForwarderInviteFinishController', function ($translate, $stateParams, Auth, $state, Forwarder) {
            var vm = this;

            Forwarder.invitation({key: $stateParams.key}).$promise.then(function (data) {
                vm.item = data;
                vm.refreshLanguage();
            });
            vm.finishRegistration = function () {
                Forwarder.invitationFinish(vm.item).$promise.then(function () {
                    Auth.logout();
                    $state.go('home');
                });
            };

            vm.refreshLanguage = function () {
                $translate.use(vm.item.language);
            };

        });

})();
