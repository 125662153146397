(function () {
    'use strict';

    angular
        .module('awizApp')
        .controller('EntryReservationStatusChangeController', function ($timeout, $rootScope, $scope, $uibModalInstance, EntryReservation, params) {
            var vm = this;

            vm.item = {
                id: params.entryId
            };

            vm.options = params;

            vm.setNewStatus = function (status) {
                vm.item.status = status;
            };

            vm.operationStatus = {
                CONFIRM: {
                    statuses: [ 'CONFIRMED' ]
                },
                UNCONFIRM: {
                    statuses: [ 'UNCONFIRMED' ]
                },
                FINISH: {
                    statuses: [ 'PROCESSED', 'PROCESSED_DELAYED', 'UNPROCESSED_DID_NOT_ARRIVE' ]
                },
                CANCEL: {
                    statuses: [ 'CANCELLED' ]
                }
            };

            vm.getStatuses = function () {
                var statuses = vm.operationStatus[params.operation].statuses;
                if(statuses.length === 1){
                    vm.setNewStatus(statuses[0]);
                }
                return statuses;
            };

            vm.isCommentRequired = function () {
                return vm.options.commentRequired || vm.item.status === 'UNPROCESSED_DID_NOT_ARRIVE';
            };


            EntryReservation.get({id: params.entryId}).$promise.then(function (data) {
                vm.entry = data;

            });
            vm.onTimeChangeCheckbox = function(){
                if(vm.item.timeChange){
                    vm.item.startTime = vm.entry.startTime;
                }
                else {
                    delete vm.item.timeChange;
                    delete vm.item.startTime;
                }
            };

            vm.calculateEndTime = function () {
                if (!vm.entry.parcelType || !vm.entry.parcelQuantity) {
                    delete vm.calculatedEndTime;
                    return;
                }

                var processingTime = vm.entry.parcelType.processingTimeInMin * vm.entry.parcelQuantity;
                vm.calculatedEndTime = moment(vm.item.startTime).add(processingTime, 'm');
            };

            vm.canChangeTime = function(){
                return vm.entry && vm.entry.status === 'UNCONFIRMED' && vm.item && vm.item.status === 'CONFIRMED';
            };

            vm.save = function () {
                return EntryReservation.changeStatus(vm.item).$promise.then(function () {
                    $rootScope.$broadcast('entry:updated');
                    $uibModalInstance.close();
                });
            };

            vm.close = function () {
                $uibModalInstance.dismiss('cancel');
            };

        });

})();
