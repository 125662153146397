(function () {
    'use strict';

    angular
        .module('awizApp')
        .controller('SmsSendDialogController', function ($uibModalInstance, phone, SMS) {
            var vm = this;

            vm.item = {
                phone: phone
            };

            vm.phonePattern = "^$|\\d{9}";

            vm.submit = function () {
                return SMS.sendMessage(vm.item).$promise.then(function () {
                    $uibModalInstance.close();
                });
            };

            vm.close = function () {
                $uibModalInstance.dismiss('cancel');
            };

        });

})();
