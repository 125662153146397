(function () {
    'use strict';

    angular
        .module('awizApp')
        .controller('ForwarderInvitationsDialogController', function ($uibModalInstance, Forwarder) {
            var vm = this;

            refreshInvitations();

            vm.sendAgain = function (invitation) {
                return Forwarder.invitationSendAgain({id: invitation.id}).$promise;
            };

            vm.delete = function (invitation) {
                return Forwarder.invitationDelete({id: invitation.id}).$promise.then(refreshInvitations);
            };

            vm.close = function () {
                $uibModalInstance.dismiss('cancel');
            };

            function refreshInvitations() {
                Forwarder.invitations().$promise.then(function (result) {
                    vm.invitations = result;
                });
            }
        });

})();
