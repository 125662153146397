(function() {
    'use strict';

    angular
        .module('awizApp')
        .controller('UserManagementLoginAsController', function ($rootScope, $uibModalInstance, entity, Auth, AlertService, $state) {
            var vm = this;

            vm.user = entity;
            vm.clear = function () {
                $uibModalInstance.dismiss('cancel');
            };

            vm.confirmLoginAs = function () {
                Auth.loginAs(vm.user.email).then(function (result) {
                    AlertService.success("user.events.signed-in-as", { param : result.firstName + " " + result.lastName });
                    $uibModalInstance.close(true);
                    $state.go('home');
                });
            };

        });

})();
