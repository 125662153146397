(function () {
    'use strict';

    angular
        .module('awizApp')
        .controller('EntryReservationCarrierChangeController', function ($timeout, $rootScope, $uibModalInstance, EntryReservation, EntryReservationService, entryId) {
            var vm = this;

            EntryReservation.get({id: entryId}).$promise.then(function (data) {
                vm.entry = data;
                vm.item = {
                    id: data.id,
                    actionType: data.actionType,
                    carrierName: data.carrierName,
                    driverName: data.driverName,
                    driverPhone: data.driverPhone,
                    driverIdentityCard: data.driverIdentityCard,
                    vehicleRegistrationNumber: data.vehicleRegistrationNumber,
                    trailer: data.trailer,
                    trailerRegistrationNumber: data.trailerRegistrationNumber,
                    trackingNumber: data.trackingNumber,
                    orderNumber: data.orderNumber
                };
            });

            vm.onTrailerChange = function () {
                delete vm.item.trailerRegistrationNumber;
            };

            vm.canSave = function(){
                return vm.entry && (vm.entry.status === 'UNCONFIRMED' || vm.entry.status === 'CONFIRMED');
            };

            vm.save = function () {
                return EntryReservation.carrierChange(vm.item).$promise.then(function () {
                    $rootScope.$broadcast('entry:updated');
                    $uibModalInstance.close();
                });
            };

            vm.close = function () {
                $uibModalInstance.dismiss('cancel');
            };

        });

})();
