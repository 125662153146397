(function() {
    'use strict';
    angular
        .module('awizApp')
        .factory('Forwarder', function ($resource) {
            return $resource('api/forwarder/:id', {id: '@id'}, {
                'query': { method: 'GET', isArray: true },
                'update': { method:'PUT' },
                'invitation': { url: "api/forwarder/invitation" },
                'invitations': { url: "api/forwarder/invitations", isArray: true },
                'invitationDelete': { url: "api/forwarder/invitations/:id", method: 'DELETE' },
                'invitationSendAgain': { url: "api/forwarder/invitations/:id/send-again", method: 'POST' },
                'invitationRequest': { url: "api/forwarder/invitation-request", method: 'POST' },
                "invitationFinish" : { url: "api/forwarder/invitation-finish", method: 'POST' },
                'autocomplete': { url: 'api/forwarder-autocomplete', isArray: true }
            });
        });

})();
