(function () {
    'use strict';

    angular
        .module('awizApp')
        .controller('WarehouseDialogController', function ($timeout, $scope, $uibModalInstance, Warehouse, item) {
            var vm = this;
            vm.item = item;

            vm.save = function () {
                var tmp = angular.copy(vm.item);

                function onSaveSuccess(result) {
                    $scope.$emit('slot:updated', result);
                    $uibModalInstance.close(result);
                }

                if (null === tmp.id) {
                    return Warehouse.save(tmp, onSaveSuccess);
                } else {
                    return Warehouse.update(tmp, onSaveSuccess);
                }
            };

            vm.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            };

        });

}());
