(function () {
    'use strict';

    angular
        .module('awizApp')
        .directive('bindHtmlCompile', function ($compile) {
            return {
                restrict: 'A',
                link: function (scope, element, attrs) {
                    scope.$watch(function () {
                        return scope.$eval(attrs.bindHtmlCompile);
                    }, function (value) {
                        // Incase value is a TrustedValueHolderType, sometimes it
                        // needs to be explicitly called into a string in order to
                        // get the HTML string.
                        element.html(value && value.toString());
                        // If scope is provided use it, otherwise use parent scope
                        var compileScope = scope;
                        if (attrs.bindHtmlScope) {
                            compileScope = scope.$eval(attrs.bindHtmlScope);
                        }
                        $compile(element.contents())(compileScope);
                    });
                }
            };
        })
        .provider('ConfirmDialogService', function () {
            /*jshint validthis: true */
            this.$get = function ($timeout, $sce, $translate, toaster, $uibModal) {
                var defaultParams = {
                    header: $translate.instant("entity.confirm.title"),
                    message: $translate.instant("entity.confirm.message"),
                    confirmPromise: function () {
                        return $q.defer().promise;
                    },
                    confirmedSuccess: function () {

                    },
                    confirmedError: function () {

                    }
                };

                return {
                    open: open
                };

                function open(params) {

                    angular.extend(defaultParams, params);
                    if (defaultParams.messageTranslated) {
                        defaultParams.message = $translate.instant(defaultParams.messageTranslated.key, defaultParams.messageTranslated.params);
                    }

                    return $uibModal.open({
                        templateUrl: 'app/components/confirm-dialog/confirm-dialog.html',
                        controller: function ($uibModalInstance, params) {
                            var vm = this;
                            vm.params = params;

                            vm.cancel = function () {
                                $uibModalInstance.dismiss('cancel');
                            };
                            vm.confirm = function () {
                                vm.processPromise = defaultParams.confirmPromise();
                                vm.processPromise
                                    .then(function () {
                                        $uibModalInstance.close(true);
                                        defaultParams.confirmedSuccess();
                                    })
                                    .catch(function (reason) {
                                        defaultParams.confirmedError();
                                    });
                            };
                        },
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            params: defaultParams
                        }
                    }).result;
                }

            };

        });


})();
