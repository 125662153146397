(function () {
    'use strict';

    angular
        .module('awizApp')
        .controller('ParcelTypeDialogController', function ($scope, $stateParams, $uibModalInstance, ParcelType, item) {
            var vm = this;

            vm.item = {
                id: null
            };

            if (item) {
                vm.item = angular.copy(item);
            }

            vm.save = function () {
                if (vm.item.id !== null) {
                    ParcelType.update(vm.item).$promise.then(onSaveSuccess);
                } else {
                    ParcelType.save(vm.item).$promise.then(onSaveSuccess);
                }

                function onSaveSuccess(result) {
                    $scope.$emit('parcelType:updated', result);
                    $uibModalInstance.close(result);
                }
            };

            vm.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            };
        });

})();
