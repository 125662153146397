(function () {
    'use strict';

    angular
        .module('awizApp')
        .controller('UserDialogController', function ($rootScope, $uibModalInstance, user, CompanyUser) {
            var vm = this;
            vm.authorities = ['ROLE_OWNER', 'ROLE_USER'];
            vm.setPassword = false;
            vm.user = user;

            vm.save = function() {
                if (user.id) {
                    return CompanyUser.update(vm.user).$promise.then(function (result){
                        $rootScope.$broadcast('user:updated');
                        $uibModalInstance.close(result);
                    });
                } else {
                    return CompanyUser.save(vm.user).$promise.then(function (result) {
                        $rootScope.$broadcast('user:updated');
                        $uibModalInstance.close(result);
                    });
                }
            };

            vm.clear = function() {
                $uibModalInstance.dismiss('cancel');
            };
        });

})();
