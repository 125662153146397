(function() {
    'use strict';

    angular
        .module('awizApp')
        .factory('notificationInterceptor', function ($q, AlertService) {
            return {
                response: response
            };

            function response (response) {
                var alertKey = response.headers('x-awizApp-alert');
                if (angular.isString(alertKey)) {
                    AlertService.success(alertKey, { param : response.headers('x-awizApp-params')});
                }
                return response;
            }
        });

})();
