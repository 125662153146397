(function() {
    'use strict';
    angular
        .module('awizApp')
        .factory('ProductListHelper', function () {
            return {
                getSizeCollection: getSizeCollection,
                getColorCollection: getColorCollection,
                selectAll: selectAll,
                deselectAll: deselectAll,
                selectItem: selectItem
            };

            function getSizeCollection(products, allProducts) {
                if(!angular.isArray(products) || products.length === 0 || !angular.isArray(allProducts) || allProducts.length === 0)
                    return [];
                var shopModels = products
                    // .filter(function (p) {
                    //     return p.shopModel.length > 0;
                    // })
                    .map(function (p) {
                        return p.shopModel;
                    });

                return allProducts.filter(function (item) {
                    return shopModels.indexOf(item.shopModel) >= 0;
                });
            }
            function getColorCollection(products, allProducts) {
                if(!angular.isArray(products) || products.length === 0)
                    return [];
                var shopModels = products
                    // .filter(function (p) {
                    //     return p.collectionModel.length > 0;
                    // })
                    .map(function (p) {
                        return p.collectionModel;
                    });

                return allProducts.filter(function (item) {
                    return shopModels.indexOf(item.collectionModel) >= 0;
                });
            }

            function deselectAll(items){
                angular.forEach(items, function(item) {
                    item.selected = false;
                });
            }

            function selectItem(item, selectedItems) {
                if(!item.selected){
                    selectedItems.push(item);
                    item.selected = true;
                }
                else {
                    item.selected = false;
                    selectedItems.splice(selectedItems.indexOf(item), 1);
                }
            }

            function selectAll(items, selectedItems, checkbox){
                // var newValue = (50 !== selectedItems.length);
                var newValue = checkbox;
                angular.forEach(items, function(item) {
                    if(newValue){
                        if(!item.selected){
                            item.selected = newValue;
                            selectedItems.push(item);
                        }
                    }
                    else{
                        if(item.selected){
                            item.selected = newValue;
                            selectedItems.splice(selectedItems.indexOf(item), 1);
                        }
                    }
                });

            }
        });

})();
