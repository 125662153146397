(function() {
    'use strict';

    angular
        .module('awizApp')
        .controller('SidebarController', function ($state, ENV) {
            var vm = this;
            vm.isNavbarCollapsed = true;
            vm.inProduction = ENV === 'prod';
            vm.$state = $state;

            vm.toggleNavbar = function () {
                vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
            };
            vm.collapseNavbar = function () {
                vm.isNavbarCollapsed = true;
            };

        });

})();
