(function () {
    'use strict';
    angular
        .module('awizApp')
        .factory('SMS', function ($resource) {
            return $resource('api/sms/:id', {id: '@id'}, {
                'sendMessage': {method: 'POST'}
            });
        })
        .service('SmsService', function ($uibModal) {

            this.sendMessage = function (phone) {
                return $uibModal.open({
                    templateUrl: 'app/entities/sms/send-dialog.html',
                    controller: 'SmsSendDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        phone: function () {
                            return phone;
                        }
                    }
                }).result;
            };
        });

})();
