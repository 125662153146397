(function () {
    'use strict';

    angular
        .module('awizApp')
        .directive('commentsWidget', function ($rootScope, $interval, EntryReservation, EntryReservationService) {
                return {
                    restrict: 'AE',
                    replace: true,
                    scope: {},
                    templateUrl: "app/entities/entry-reservation/widget-comments/comments.html",
                    link: function (scope) {
                        scope.page = 0;
                        scope.pageSize = 5;

                        scope.reloadData = reloadData;
                        scope.nextPage = function () {
                            scope.page += 1;
                            reloadData();
                        };
                        scope.previousPage = function () {
                            if (scope.page !== 0) {
                                scope.page -= 1;
                            }
                            reloadData();
                        };
                        scope.show = function (id) {
                            EntryReservationService.show(id);
                        };
                        scope.isLastPage = function () {
                            return (scope.page + 1) * scope.pageSize >= scope.itemsCount;
                        };

                        reloadData();
                        function reloadData() {
                            EntryReservation.lastComments({page: scope.page, size: scope.pageSize}, onSuccess, onError);

                            function onSuccess(response, headers) {
                                scope.items = response;
                                scope.itemsCount = Number(headers('X-Total-Count'));
                            }

                            function onError() {
                                scope.items = [];
                                scope.itemsCount = 0;
                            }
                        }

                        scope.$on('entry:updated', function () {
                            reloadData();
                        });

                        var intervalPromise;
                        function initAutoRefresh() {
                            $interval.cancel(intervalPromise);
                            reloadData();
                            intervalPromise = $interval(initAutoRefresh, 30000);
                        }

                        initAutoRefresh();
                        scope.$on('$destroy', function () {
                            $interval.cancel(intervalPromise);
                        });
                    }
                };
            });

})();
