(function() {
    'use strict';

    angular
        .module('awizApp')
        .config(function ($stateProvider) {
            $stateProvider.state('requestReset', {
                parent: 'account',
                url: '/reset/request',
                data: {
                    authorities: [],
                    pageTitle: 'reset.request.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/reset/request/reset.request.html',
                        controller: 'RequestResetController',
                        controllerAs: 'vm'
                    }
                }
            });
        });

})();
