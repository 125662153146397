(function () {
    'use strict';

    angular
        .module('awizApp')
        .factory('Warehouse', function ($resource) {
            return $resource('api/warehouse/:id', {}, {
                'query': {method: 'GET', isArray: true},
                'update': {method: 'PUT'},
                'autocomplete': {method: 'GET', url: 'api/warehouse-autocomplete', isArray: true}
            });
        })
        .factory('WarehouseService', function (Warehouse, ConfirmDialogService) {
            return {
                deleteWarehouse: deleteWarehouse
            };

            function deleteWarehouse(item) {
                return ConfirmDialogService.open({
                    messageTranslated: {
                        key: "warehouse.labels.delete",
                        params: {param: item.name}
                    },
                    confirmPromise: function () {
                        return Warehouse.delete({id: item.id}).$promise;
                    }
                });
            }
        })
    ;

}());
