(function () {
    'use strict';

    angular
        .module('awizApp')
        .component('jhiAlertError', {
            template: '',
            controller: function ($scope, AlertService, $rootScope, $translate) {
                var vm = this;

                vm.alerts = [];

                function addErrorAlert(message, key, data) {
                    key = key && key !== null ? key : message;
                    vm.alerts.push(
                        AlertService.add(
                            {
                                type: 'danger',
                                msg: key,
                                params: data,
                                timeout: 5000,
                                toast: AlertService.isToast(),
                                scoped: true
                            },
                            vm.alerts
                        )
                    );
                }

                var cleanHttpErrorListener = $rootScope.$on('awizApp.httpError', function (event, httpResponse) {
                    var i;
                    event.stopPropagation();
                    switch (httpResponse.status) {
                        // connection refused, server not reachable
                        case -1:
                            addErrorAlert('No connection', 'error.no-connection');
                            break;
                        case 0:
                            addErrorAlert('Server not reachable', 'error.server.not.reachable');
                            break;

                        case 400:
                            var errorHeader = httpResponse.headers('X-awizApp-error');
                            var entityKey = httpResponse.headers('X-awizApp-params');
                            if (errorHeader) {
                                var entityName = $translate.instant('global.menu.entities.' + entityKey);
                                addErrorAlert(errorHeader, errorHeader, {entityName: entityName});
                            } else if (httpResponse.data && httpResponse.data.fieldErrors) {
                                for (i = 0; i < httpResponse.data.fieldErrors.length; i++) {
                                    var fieldError = httpResponse.data.fieldErrors[i];
                                    // convert 'something[14].other[4].id' to 'something[].other[].id' so translations can be written to it
                                    var convertedField = fieldError.field.replace(/\[\d*\]/g, '[]');
                                    var fieldName = $translate.instant('validation.' + fieldError.objectName + '.' + convertedField);
                                    addErrorAlert('Field ' + fieldName + ' cannot be empty', 'error.' + fieldError.message, {fieldName: fieldName});
                                }
                            } else if (httpResponse.data && httpResponse.data.message && httpResponse.data.params) {
                                // var messageTranslated = $translate.instant(httpResponse.data.message, httpResponse.data.params);
                                addErrorAlert(httpResponse.data.message, httpResponse.data.message, httpResponse.data);
                            } else {
                                addErrorAlert(httpResponse.data);
                            }
                            break;

                        default:
                            if (httpResponse.data && httpResponse.data.message) {
                                var messageTranslated = $translate.instant(httpResponse.data.message, httpResponse.data.params);
                                addErrorAlert(messageTranslated);
                            } else {
                                var message = angular.toJson(httpResponse);
                                console.log(message);
                                // addErrorAlert(message);
                            }
                    }
                });

                $scope.$on('$destroy', function () {
                    if (angular.isDefined(cleanHttpErrorListener) && cleanHttpErrorListener !== null) {
                        cleanHttpErrorListener();
                        vm.alerts = [];
                    }
                });
            }
        });

})();
