(function () {
    'use strict';

    angular
        .module('awizApp')
        .controller('CustomerNewAccountDialogController', function ($timeout, $scope, $uibModalInstance, Customer) {
            var vm = this;
            vm.newAccount = {};

            vm.save = function () {
                return Customer.save(vm.newAccount, onSaveSuccess).$promise;
                function onSaveSuccess(result) {
                    $uibModalInstance.close(result);
                }
            };

            vm.close = function () {
                $uibModalInstance.dismiss('cancel');
            };

        });
})();
