(function() {
    'use strict';

    angular
        .module('awizApp')
        .config(function ($stateProvider) {
            $stateProvider.state('sessions', {
                parent: 'account',
                url: '/sessions',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'sessions.page-title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/sessions/sessions.html',
                        controller: 'SessionsController',
                        controllerAs: 'vm'
                    }
                }
            });
        });

})();
