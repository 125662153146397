(function () {
    'use strict';

    angular
        .module('awizApp')
        .filter('join', function () {
            return function join(array, separator, prop) {
                if (!Array.isArray(array)) {
                    return array; // if not array return original - can also throw error
                }
                array = array.filter(function (e) {
                    return angular.isDefined(e);
                });

                return (!!prop ? array.map(function (item) {
                    return item[prop];
                }) : array).join(separator);
            };
        })
        .factory('ModalUtil', function ($state) {
            return {
                defaultThen: defaultThen,
                defaultCatch: defaultCatch
            };

            function defaultThen(transitionFrom, transitionParamsFrom) {
                if (transitionFrom.abstract) {
                    $state.go('^');
                } else {
                    $state.go(transitionFrom, transitionParamsFrom, {reload: true});
                }
            }

            function defaultCatch (transitionFrom, transitionParamsFrom) {
                if (transitionFrom.abstract) {
                    $state.go('^');
                } else {
                    $state.go(transitionFrom, transitionParamsFrom);
                }
            }

        });

})();
