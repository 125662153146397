(function() {
    'use strict';

    angular
        .module('awizApp')
        .directive('avSort', function () {
            return {
                restrict: 'A',
                scope: {
                    sortBy: '=avSort',
                    predicate: '=',
                    ascending: '=',
                    callback: '&'
                },
                controllerAs: 'vm',
                bindToController: true,
                controller: function ($scope, $element) {
                    var vm = this;

                    $element.bind('click', function () {
                        sort(vm.sortBy);
                    });

                    vm.resetClasses = function () {
                        var allThIcons = $element.find('span.glyphicon'),
                            sortIcon = 'glyphicon-sort',
                            sortAsc = 'glyphicon-sort-by-attributes',
                            sortDesc = 'glyphicon-sort-by-attributes-alt';

                        allThIcons.removeClass(sortAsc + ' ' + sortDesc);
                        allThIcons.addClass(sortIcon);
                    };

                    vm.triggerApply = function (values)  {
                        vm.resetClasses();
                        if (values && values[0] !== '_score') {
                            vm.applyClass($element.find('th[jh-sort-by=\'' + values[0] + '\']'));
                        }
                    };

                    $scope.$watchGroup(['vm.predicate', 'vm.ascending'], vm.triggerApply);
                    vm.triggerApply();

                    function sort(column){
                        if(vm.predicate === column){
                            vm.ascending = !vm.ascending;
                        }
                        else{
                            vm.predicate = column;
                            vm.ascending = true;
                        }
                        vm.resetClasses();
                        $scope.$apply();
                    }

                }
            };
        });

})();
