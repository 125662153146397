(function() {
    'use strict';

    angular
        .module('awizApp')
        .component('parcelTypes', {
            bindings: {
                filters: '=',
                page: '='
            },
            templateUrl: 'app/entities/parcel-type/list.html',
            controller: function ($state, ParcelTypeService) {
                this.submit = function () {
                    $state.go($state.current, this.filters);
                };
                this.create = function () {
                    ParcelTypeService.add().then(reloadData);
                };

                this.edit = function (item) {
                    ParcelTypeService.edit(item).then(reloadData);
                };

                this.delete = function (item) {
                    ParcelTypeService.delete(item).then(reloadData);
                };

                function reloadData() {
                    $state.reload();
                }
            }
        })
        .config(function ($stateProvider) {
            $stateProvider
                .state('parcelType', {
                    parent: 'entity',
                    url: '/parcel-type?page&sort&search&size',
                    data: {
                        authorities: ['ROLE_USER']
                    },
                    views: {
                        'content@': 'parcelTypes'
                    },
                    params: {
                        page: {
                            value: '1',
                            squash: true
                        },
                        size: {
                            value: '50',
                            squash: true
                        },
                        sort: {
                            value: 'name,asc',
                            squash: true
                        },
                        search: null
                    },
                    resolve: {
                        filters: function ($transition$) {
                            return angular.copy($transition$.params());
                        },
                        page: function (ParcelType, $q, $stateParams) {
                            var deferred = $q.defer();
                            var queryParams = angular.copy($stateParams);
                            queryParams.page -= 1;
                            ParcelType.query(queryParams, onSuccess, onError);

                            function onSuccess(response, headers) {
                                deferred.resolve({
                                    items: response,
                                    itemsCount: headers('X-Total-Count')
                                });
                            }
                            function onError() {
                                deferred.reject();
                            }

                            return deferred.promise;
                        }
                    }
                });
        });

})();
