(function() {
    'use strict';

    angular
        .module('awizApp')
        .component('mapLinkToThick', {
            bindings: {
                address: '<',
                coordinates: '<'
            },
            template: '<a ng-if="$ctrl.label" href="https://www.google.com/maps/search/?api=1&query={{$ctrl.destination}}" target="_blank"\n' +
                '   title="{{ \'entry.label.show-on-map\' | translate }}">\n' +
                ' {{$ctrl.label}}' +
                '</a>',
            controller: function () {
                this.destination = null;
                this.label = null;
                this.$onInit = function () {
                    if(this.address){
                        this.label = this.address.street + ', ' + this.address.postcode + ' ' + this.address.city;
                        this.destination = this.address.street + ', ' + this.address.postcode + ' ' + this.address.city;
                    }
                    if(this.coordinates){
                        this.destination = this.coordinates.longitude + ',' + this.coordinates.latitude;
                    }
                };
            }
        })
        .component('mapLinkToDirections', {
            bindings: {
                address: '<',
                coordinates: '<'
            },
            template: '<a ng-if="$ctrl.destination" href="https://www.google.com/maps/dir/?api=1&destination={{$ctrl.destination}}" target="_blank"\n' +
                '   title="{{ \'entry.label.prepare-route\' | translate }}">\n' +
                ' <i class="fas fa-route fa-w-15"></i>' +
                '</a>',
            controller: function () {
                this.destination = null;
                this.$onInit = function () {
                    if(this.coordinates){
                        this.destination = this.coordinates.longitude + ',' + this.coordinates.latitude;
                    }
                    else if(this.address){
                        this.destination = this.address.street + ', ' + this.address.postcode + ' ' + this.address.city;
                    }
                };
            }
        })
    ;

})();
