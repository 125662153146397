(function () {
    'use strict';

    angular
        .module('awizApp')
        .controller('EntryReservationTvController', function($scope, $state, Principal, EntryReservation) {
            var vm = this;
            vm.$state = $state;
            vm.isAuthenticated = null;

            var bodyElement = angular.element(document.querySelector('body'));
            bodyElement.addClass('tv-mode');

            $scope.$on('$destroy', function () {
                bodyElement.removeClass('tv-mode');
            });

            $scope.$watch(function () {
                return Principal.isAuthenticated();
            }, function () {
                vm.isAuthenticated = Principal.isAuthenticated;
                loadData();
            });

            vm.hasSlots = Principal.hasSlots;

            function loadData() {
                EntryReservation.mySlots().$promise.then(function (result) {
                    vm.slots = result;
                    vm.colsPerSlot = vm.slots.length > 0 ? 12 / vm.slots.length : 12;
                });
            }
        })
        .config(function ($stateProvider) {
            $stateProvider.state('tv', {
                url: '/tv',
                data: {
                    authorities: ['ROLE_USER']
                },
                views: {
                    content: {
                        templateUrl: 'app/entities/entry-reservation/tv-mode/tv-mode.html',
                        controller: 'EntryReservationTvController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    authorize: function (Auth) {
                        return Auth.authorize();
                    },
                    translatePartialLoader: function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('settings');
                        $translatePartialLoader.addPart('slot-management');
                        $translatePartialLoader.addPart('user-management');
                        $translate.refresh();
                    }
                }
            });
        });

})();
