(function () {
    'use strict';

    angular
        .module('awizApp')
        .factory('ParcelType', function ($resource) {
            return $resource('api/parcel-type/:id', {}, {
                'query': {method: 'GET', isArray: true},
                'update': {method: 'PUT'},
                'forSlot': {method: 'GET', isArray: true, url: 'api/parcel-type/for-slot'}
            });
        })
        .service('ParcelTypeService', function ($uibModal, ParcelType, ConfirmDialogService) {

            this.add = function (params) {
                return $uibModal.open({
                    templateUrl: 'app/entities/parcel-type/dialog.html',
                    controller: 'ParcelTypeDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        item: function () {
                            return params;
                        }
                    }
                }).result;
            };

            this.edit = function (item) {
                return $uibModal.open({
                    templateUrl: 'app/entities/parcel-type/dialog.html',
                    controller: 'ParcelTypeDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        item: function () {
                            return item;
                        }
                    }
                }).result;
            };

            this.delete = function (item) {
                return ConfirmDialogService.open({
                    messageTranslated: {
                        key: "parcelType.label.delete",
                        params: { param: item.name }
                    },
                    confirmPromise: function () {
                        return ParcelType.delete({id: item.id}).$promise;
                    }
                });
            };
        })
    ;

})();
