(function () {
    'use strict';

    angular
        .module('awizApp')
        .component('tableTitle', {
            bindings: {
                titleKey: '@',
                tableCount: '<'
            },
            template: "<div>\n" +
                "    <table-filters-switch></table-filters-switch>" +
                "<div class=\"clearfix\">\n" +
                "        <h5>{{$ctrl.titleKey | translate}}&nbsp;(<invisible-on-loading>{{$ctrl.tableCount}}</invisible-on-loading>)</h5>\n" +
                "    </div>" +
                "</div>",
            controller: function () {
            }
        })
        .component('invisibleOnLoading', {
            controller: function ($transitions, $element) {
                this.isLoading = false;
                var onBefore = $transitions.onBefore({}, function (transition) {
                    if (transition.from() === transition.to()) {
                        this.isLoading = true;
                        // $element.hide();
                        $element.html('<span>...</span>');
                    }
                }.bind(this));

                var onFinish = $transitions.onFinish({}, function (transition) {
                    if (transition.from() === transition.to()) {
                        this.isLoading = false;
                        $element.show();
                    }
                }.bind(this));

                var onError = $transitions.onError({}, function (transition) {
                    if (transition.from() === transition.to()) {
                        this.isLoading = false;
                        $element.show();
                    }
                }.bind(this));

                this.$onDestroy = function () {
                    onBefore();
                    onFinish();
                    onError();
                };
            }
        })
        .component('stateLoadingSpinner', {
            template: "<div class=\"state-loading-spinner\" ng-if=\"$ctrl.isLoading\"><i class=\"fas fa-spinner fa-spin fa-lg fa-2x\"></i></div>",
            controller: function ($transitions) {

                this.isLoading = false;

                var onBefore = $transitions.onBefore({}, function (transition) {
                    if (transition.from() !== transition.to()) {
                        this.isLoading = true;
                    }
                }.bind(this));

                var onFinish = $transitions.onFinish({}, function (transition) {
                    if (transition.from() !== transition.to()) {
                        this.isLoading = false;
                    }
                }.bind(this));

                var onError = $transitions.onError({}, function (transition) {
                    if (transition.from() !== transition.to()) {
                        this.isLoading = false;
                    }
                }.bind(this));

                this.$onDestroy = function () {
                    onBefore();
                    onFinish();
                    onError();
                };

            }
        });
}());
