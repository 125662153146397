(function () {
    'use strict';

    angular
        .module('awizApp')
        .controller('EntryReservationTimeChangeController', function ($timeout, $rootScope, $uibModalInstance, EntryReservation, EntryReservationService, entryId) {
            var vm = this;

            EntryReservation.get({id: entryId}).$promise.then(function (data) {
                vm.entry = data;
                vm.item = {
                    id: data.id,
                    startTime: data.startTime
                };
                vm.calculatedEndTime = data.endTime;
            });

            vm.calculateEndTime = function () {
                vm.calculatedEndTime = EntryReservationService.calculateEndTime(vm.entry.parcelType.processingTimeInMin, vm.entry.parcelQuantity, vm.item.startTime);
            };

            vm.canSave = function(){
                return vm.entry && vm.entry.status === 'UNCONFIRMED';
            };

            vm.save = function () {
                return EntryReservation.timeChange(vm.item).$promise.then(function () {
                    $rootScope.$broadcast('entry:updated');
                    $uibModalInstance.close();
                });
            };

            vm.close = function () {
                $uibModalInstance.dismiss('cancel');
            };

        });

})();
