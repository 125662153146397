(function() {
    'use strict';

    angular
        .module('awizApp')
        .factory('LogsService', function ($resource) {
            return $resource('api/logs', {}, {
                'findAll': {method: 'GET', isArray: true},
                'changeLevel': {method: 'PUT'}
            });
        });

})();
