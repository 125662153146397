(function() {
    'use strict';

    angular
        .module('awizApp')
        .directive('selectCustomer', function (Customer) {
            return {
                require: '^ngModel',
                scope: {
                    type: "=",
                    modelValue: '=ngModel',
                    options: '='
                },
                templateUrl: 'app/admin/customer-management/select-customer/select-customer.html',
                link: function(scope, $element, $attrs, ngModelCtrl) {

                    var defaultOptions = {
                        // activityFiltering: true
                    };

                    scope.requestOptions = angular.extend({}, defaultOptions, scope.options);
                    var queryPromise = Customer.autocomplete(scope.requestOptions).$promise;
                    queryPromise
                        .then(function (items) {
                            scope.list = items;
                        })
                        .catch(function (reason) {
                            scope.list = [];
                        });

                    scope.setValue = function(value){
                        ngModelCtrl.$setViewValue(value);
                    };

                }
            };
        });
})();
