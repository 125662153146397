(function () {
    "use strict";

    angular.module('awizApp')
        .directive('entryComments', function ($rootScope, EntryReservation) {
            return {
                restrict: 'E',
                scope: {
                    entryId: '='
                },
                templateUrl: 'app/entities/entry-reservation/entry-comments/entry-comments.html',
                link: function (scope) {

                    loadComments(scope.entryId);
                    resetNewComment();

                    scope.postComment = function () {
                        return EntryReservation.postComment({id: scope.entryId}, scope.newComment).$promise.then(function () {
                            $rootScope.$broadcast('entry:updated');
                            resetNewComment();
                        });
                    };

                    scope.$on('entry:updated', function () {
                        loadComments(scope.entryId);
                    });

                    function resetNewComment() {
                        scope.newComment = {
                            text: ''
                        };
                    }
                    function loadComments(id) {
                        EntryReservation.comments({id: id}).$promise.then(function (comments) {
                            scope.comments = comments;
                        });
                    }
                }
            };
        });

})();
