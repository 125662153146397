(function () {
    'use strict';

    angular
        .module('awizApp')
        .factory('CompanyUser', function ($resource) {
            return $resource('api/users/:login:id', {}, {
                'query': {method: 'GET', isArray: true},
                'get': {
                    method: 'GET',
                    transformResponse: function (data) {
                        data = angular.fromJson(data);
                        return data;
                    }
                },
                'save': {method: 'POST'},
                'update': {method: 'PUT'},
                'delete': {method: 'DELETE'}
            });
        });

})();
