(function() {
    'use strict';

    angular
        .module('awizApp')
        .directive('jhSortBy', function () {
            return {
                restrict: 'A',
                scope: false,
                require: '^jhSort',
                link: linkFunc
            };

            function linkFunc(scope, element, attrs, parentCtrl) {
                element.append('<span class="glyphicon glyphicon-sort"></span>');
                element.bind('click', function () {
                    parentCtrl.sort(attrs.jhSortBy);
                });
            }
        });

})();
