(function () {
    'use strict';

    angular
        .module('awizApp')
        .component('userManagement', {
            bindings:{
                filters: '=',
                page: '=',
                currentAccount: '<'
            },
            templateUrl: 'app/admin/user-management/user-management.html',
            controller: function ($state, $uibModal, User) {
                this.setActive = function (user, isActivated) {
                    user.activated = isActivated;
                    User.update(user).$promise.then(reload);
                };

                this.logAsUser = function (user) {
                    $uibModal.open({
                        templateUrl: 'app/admin/user-management/user-management-loginas-dialog.html',
                        controller: 'UserManagementLoginAsController',
                        controllerAs: 'vm',
                        size: 'sm',
                        resolve: {
                            entity: function () {
                                return user;
                            }
                        }
                    });
                };

                this.submit = function () {
                    $state.go($state.current, this.filters);
                };
                function reload() {
                    $state.reload();
                }
            }
        })
        .config(function ($stateProvider) {
            $stateProvider
                .state('user-management', {
                    parent: 'admin',
                    url: '/user-management?page&sort&search&{customer:int}&size',
                    data: {
                        authorities: ['ROLE_ADMIN']
                    },
                    views: {
                        'content@': 'userManagement'
                    },
                    params: {
                        page: {
                            value: '1',
                            squash: true
                        },
                        size: {
                            value: '50',
                            squash: true
                        },
                        sort: {
                            value: 'customer.name,asc',
                            squash: true
                        },
                        search: null
                    },
                    resolve: {
                        currentAccount: function(Principal){
                            return Principal.identity();
                        },
                        filters: function ($transition$) {
                            return angular.copy($transition$.params());
                        },
                        page: function (User, $q, $stateParams) {
                            var deferred = $q.defer();
                            var queryParams = angular.copy($stateParams);
                            queryParams.page -= 1;
                            User.query(queryParams, onSuccess, onError);

                            function onSuccess(response, headers) {
                                deferred.resolve({
                                    items: response,
                                    itemsCount: headers('X-Total-Count')
                                });
                            }

                            function onError() {
                                deferred.reject();
                            }

                            return deferred.promise;
                        }
                    }
                })
            ;
        });

})();
