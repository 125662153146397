(function() {
    'use strict';

    angular
        .module('awizApp')
        .controller('RegisterController', function ($translate, Auth) {
            var vm = this;

            vm.doNotMatch = null;
            vm.error = null;
            vm.errorUserExists = null;
            vm.registerAccount = {};
            vm.success = null;

            vm.register = function () {
                if (vm.registerAccount.password !== vm.confirmPassword) {
                    vm.doNotMatch = 'ERROR';
                } else {
                    vm.registerAccount.langKey = $translate.use();
                    vm.doNotMatch = null;
                    vm.error = null;
                    vm.errorUserExists = null;
                    vm.errorEmailExists = null;

                    Auth.createAccount(vm.registerAccount).then(function () {
                        vm.success = 'OK';
                    }).catch(function (response) {
                        vm.success = null;
                        vm.error = 'ERROR';
                    });
                }
            };

        });

})();
