(function () {
    'use strict';
    angular
        .module('awizApp')
        .service('CustomerService', function ($uibModal) {
            this.add = function () {
                return $uibModal.open({
                    templateUrl: 'app/admin/customer-management/new-account.html',
                    controller: 'CustomerNewAccountDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg'
                }).result;
            };

            this.edit = function (id) {
                return $uibModal.open({
                    templateUrl: 'app/admin/customer-management/dialog.html',
                    controller: 'CustomerDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        id: function () {
                            return id;
                        }
                    }
                }).result;
            };
        })
        .factory('Customer', function ($resource) {
            return $resource('api/customer/:id', {}, {
                query: {method: 'GET', isArray: true},
                update: {method: 'PUT'},
                autocomplete: {url: 'api/admin/customer-autocomplete', isArray: true}
            });
        });

})();
