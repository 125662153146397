(function() {
    'use strict';

    angular
        .module('awizApp')
        .factory('PasswordResetInit', function ($resource) {
            return $resource('api/account/reset_password/init', {}, {});
        });

})();
