(function() {
    'use strict';

    angular
        .module('awizApp')
        .factory('JhiMetricsService', function ($rootScope, $http) {
            return {
                getMetrics: getMetrics,
                threadDump: threadDump
            };

            function getMetrics () {
                return $http.get('metrics/metrics').then(function (response) {
                    return response.data;
                });
            }

            function threadDump () {
                return $http.get('dump').then(function (response) {
                    return response.data;
                });
            }
        });

})();
