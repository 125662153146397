(function () {
    'use strict';

    angular
        .module('awizApp')
        .config(function ($stateProvider) {
            $stateProvider
                .state('warehouse', {
                    parent: 'entity',
                    url: '/warehouse?page&sort&search&size',
                    data: {
                        authorities: ['ROLE_USER']
                    },
                    views: {
                        'content@': 'warehouses'
                    },
                    params: {
                        page: {
                            value: '1',
                            squash: true
                        },
                        size: {
                            value: '50',
                            squash: true
                        },
                        sort: {
                            value: 'name,asc',
                            squash: true
                        },
                        search: {
                            value: null
                        }
                    },
                    resolve: {
                        filters: function ($transition$) {
                            return angular.copy($transition$.params());
                        },
                        page: function (Warehouse, $q, $stateParams) {
                            var deferred = $q.defer();
                            var queryParams = angular.copy($stateParams);
                            queryParams.page -= 1;
                            Warehouse.query(queryParams, onSuccess, onError);

                            function onSuccess(response, headers) {
                                deferred.resolve({
                                    items: response,
                                    itemsCount: headers('X-Total-Count')
                                });
                            }

                            function onError() {
                                deferred.reject();
                            }

                            return deferred.promise;
                        }
                    }
                })
                .state('warehouseCreate', {
                    parent: 'warehouse',
                    data: {
                        authorities: ['ROLE_USER']
                    },
                    onEnter: function ($transition$, ModalUtil, $uibModal) {
                        $uibModal.open({
                            templateUrl: 'app/entities/warehouse/dialog.html',
                            controller: 'WarehouseDialogController',
                            controllerAs: 'vm',
                            backdrop: 'static',
                            size: 'md',
                            resolve: {
                                item: function () {
                                    return {
                                        id: null,
                                        active: true,
                                        timezone: moment.tz.guess()
                                    };
                                }
                            }
                        }).result
                            .then(function () {
                                ModalUtil.defaultThen($transition$.from(), $transition$.params("from"));
                            })
                            .catch(function () {
                                ModalUtil.defaultCatch($transition$.from(), $transition$.params("from"));
                            });
                    },
                    onExit: function ($uibModalStack) {
                        $uibModalStack.dismissAll();
                    }
                })
                .state('warehouse.edit', {
                    data: {
                        authorities: ['ROLE_USER']
                    },
                    params: {
                        id: {
                            value: null
                        }
                    },
                    onEnter: function ($stateParams, ModalUtil, $uibModal, Warehouse, $transition$) {
                        $uibModal.open({
                            templateUrl: 'app/entities/warehouse/dialog.html',
                            controller: 'WarehouseDialogController',
                            controllerAs: 'vm',
                            backdrop: 'static',
                            size: 'md',
                            resolve: {
                                item: function () {
                                    return Warehouse.get({id: $stateParams.id}).$promise;
                                }
                            }
                        }).result
                            .then(function () {
                                ModalUtil.defaultThen($transition$.from(), $transition$.params("from"));
                            })
                            .catch(function () {
                                ModalUtil.defaultCatch($transition$.from(), $transition$.params("from"));
                            });
                    },
                    onExit: function ($uibModalStack) {
                        $uibModalStack.dismissAll();
                    }
                });
        })
        .component('warehouses', {
            bindings: {
                filters: '=',
                page: '='
            },
            templateUrl: 'app/entities/warehouse/warehouses.html',
            controller: function ($state, $transitions, WarehouseService) {
                this.delete = function (item) {
                    return WarehouseService.deleteWarehouse(item).then(function () {
                        $state.reload();
                    });
                };
                this.submit = function () {
                    $state.go($state.current, this.filters);
                };
            }
        })
    ;
}());
