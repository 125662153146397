(function () {
    'use strict';
    angular
        .module('awizApp')
        .factory('EntryReservation', function ($resource) {
            return $resource('api/entry-reservation/:id', {id: '@id'}, {
                'query': {method: 'GET', isArray: true},
                'myReservations': {method: 'GET', isArray: true, url: 'api/entry-reservation/my-reservations'},
                'update': {method: 'PUT'},
                'mySlots': {method: 'GET', url: 'api/entry-reservation/my-slots', isArray: true},
                'changeStatus': {method: 'POST', url: 'api/entry-reservation/:id/status-change'},
                'timeChange': {method: 'POST', url: 'api/entry-reservation/:id/time-change'},
                'carrierChange': {method: 'POST', url: 'api/entry-reservation/:id/carrier-change'},
                'toggleWorkStandBy': {method: 'POST', url: 'api/entry-reservation/:id/toggle-work-stand-by'},
                'comments': {method: 'GET', url: 'api/entry-reservation/:id/comments', isArray: true},
                'lastComments': {method: 'GET', url: 'api/entry-reservation/last-comments', isArray: true},
                'postComment': {method: 'POST', url: 'api/entry-reservation/:id/comments'},
                'statuses': {method: 'GET', isArray: true, url: 'api/entry-reservation-status'}
            });
        })
        .service('EntryReservationService', function ($rootScope, $uibModal, EntryReservation) {

            this.add = function (params) {
                return $uibModal.open({
                    templateUrl: 'app/entities/entry-reservation/add.html',
                    controller: 'EntryReservationAddController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        params: params
                    }
                }).result;
            };
            this.edit = function (id) {
                return $uibModal.open({
                    templateUrl: 'app/entities/entry-reservation/edit.html',
                    controller: 'EntryReservationEditController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entryId: id
                    }
                }).result;
            };
            this.show = function (id) {
                return $uibModal.open({
                    templateUrl: 'app/entities/entry-reservation/reservation.html',
                    controller: 'EntryReservationDetailController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entryId: function (){
                            return id;
                        }
                    }
                }).result;
            };
            this.statusChange = function (id, operation, commentRequired) {
                return $uibModal.open({
                    templateUrl: 'app/entities/entry-reservation/status-change/status-change.html',
                    controller: 'EntryReservationStatusChangeController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        params: function () {
                            return {
                                entryId: id,
                                operation: operation,
                                commentRequired: commentRequired
                            };
                        }
                    }
                }).result;
            };
            this.timeChange = function (id) {
                return $uibModal.open({
                    templateUrl: 'app/entities/entry-reservation/time-change.html',
                    controller: 'EntryReservationTimeChangeController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        entryId: id
                    }
                }).result;
            };
            this.carrierChange = function (id) {
                return $uibModal.open({
                    templateUrl: 'app/entities/entry-reservation/carrier-change.html',
                    controller: 'EntryReservationCarrierChangeController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entryId: id
                    }
                }).result;
            };
            this.toggleWorkStandBy = function (entryId, workStandBy) {
                EntryReservation.toggleWorkStandBy({id: entryId, workStandBy: workStandBy}).$promise.then(function () {
                    $rootScope.$broadcast('entry:updated');
                });
            };
            this.calculateEndTime = function (parcelProcessingTimeInMin, parcelQuantity, startTime) {
                if (!parcelProcessingTimeInMin || !parcelQuantity) {
                    return;
                }

                var processingTime = parcelProcessingTimeInMin * parcelQuantity;
                return moment(startTime).add(processingTime, 'm');
            };
        })
        .service('EntryReservationUtils', function ($rootScope, $compile, $http) {
            var formatCalendarEvent = function (eventData, timezone) {
                return {
                    id: eventData.id,
                    start: moment(eventData.time.start).tz(timezone),
                    end: moment(eventData.time.end).tz(timezone),
                    className: ['calendar-reservation-status-' + eventData.status, 'calendar-work-stand-by-' + eventData.workStandBy],
                    overlap: eventData.status === 'CANCELLED',
                    workStandBy: eventData.workStandBy,
                    title: eventData.shipper.code,
                    actionType: eventData.actionType,
                    carrierName: eventData.freightInformation.carrierName,
                    driverPhone: eventData.freightInformation.driverPhone
                };
            };

            var formatCalendarReservedTime = function (eventData, timezone) {
                return {
                    start: moment(eventData.startTime).tz(timezone),
                    end: moment(eventData.endTime).tz(timezone),
                    className: "calendar-reserved-time",
                    rendering: 'background',
                    editable: false
                };
            };

            this.calendarEventRender = function (event, element, view) {
                if (event.id) {
                    if (event.workStandBy === true) {
                        element.find(".fc-list-item-title").append('<div class="pull-right"><i class="fas fa-fw fa-truck work-stand-by-true" title="{{ \'entry.events.work-stand-by.true\' | translate }}"></i></div>');
                    }

                    if (event.actionType === 'LOADING') {
                        element.find('.fc-title, .fc-list-item-title').prepend('<i class="fa fa-download fa-fw fa-smaller" translate-attr="{ title: \'entry.actionTypes.loading-forwarder\' }"></i>&nbsp;');
                    }
                    if (event.actionType === 'UNLOADING') {
                        element.find('.fc-title, .fc-list-item-title').prepend('<i class="fa fa-upload fa-fw fa-smaller" translate-attr="{ title: \'entry.actionTypes.unloading-forwarder\' }"></i>&nbsp;');
                    }
                    if (event.driverPhone) {
                        element.find('.fc-title, .fc-list-item-title').append('<div translate-attr="{ title: \'entry.vehicle.driverPhone\' }"><i class="fas fa-phone fa-fw fa-smaller"></i>&nbsp;' + event.driverPhone + '</div>');
                    }

                }
                var scope = $rootScope.$new();
                $compile(element)(scope);
            };

            this.downloadTransformEntries = function (tableFilters, start, end, timezone, callback) {
                tableFilters.start = start.toDate();
                tableFilters.end = end.toDate();
                $http({
                    url: '/api/entry-reservation',
                    params: tableFilters,
                    method: 'GET'
                }).then(function (reservationEntries) {
                    var events = [];
                    reservationEntries.data.forEach(function (eventData) {
                        events.push(formatCalendarEvent(eventData, timezone));
                    });
                    callback(events);
                });
            };

            this.fetchSlotEntries = function (slotId, start, end, timezone, params, callback) {
                params.start = start.toDate();
                params.end = end.toDate();
                $http({
                    url: '/api/entry-reservation/slot/' + slotId + '/entry',
                    params: params,
                    method: 'GET'
                }).then(function (reservationEntries) {
                    var events = [];
                    reservationEntries.data.forEach(function (eventData) {
                        events.push(formatCalendarEvent(eventData, timezone));
                    });
                    callback(events);
                });
            };

            this.fetchSlotReservedTime = function (slotId, start, end, timezone, callback) {
                $http({
                    url: '/api/entry-reservation/slot/' + slotId + '/reserved-time',
                    params: {
                        start: start.toDate(),
                        end: end.toDate()
                    },
                    method: 'GET'
                }).then(function (reservedTimeEntries) {
                    var events = [];
                    reservedTimeEntries.data.forEach(function (eventData) {
                        events.push(formatCalendarReservedTime(eventData, timezone));
                    });
                    callback(events);
                });
            };

            this.calendarSelectAllow = function (selectInfo, isSlotOwner) {
                var is15min = selectInfo.start.add(15, "m").isSame(selectInfo.end);
                var isAfterNow = selectInfo.start.isAfter(moment());
                return is15min && (isAfterNow || isSlotOwner === true);
            };
        });


})();
