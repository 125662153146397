(function () {
    'use strict';

    angular
        .module('awizApp')
        .directive('selectSlot', function (Slot) {
            return {
                require: '^ngModel',
                scope: {
                    type: "=",
                    modelValue: '=ngModel',
                    options: '='
                },
                templateUrl: 'app/entities/slot/select-slot.html',
                link: function (scope, $element, $attrs, ngModelCtrl) {

                    var defaultOptions = {
                        // activityFiltering: true
                    };

                    scope.requestOptions = angular.extend({}, defaultOptions, scope.options);
                    var queryPromise = Slot.autocomplete(scope.requestOptions).$promise;
                    queryPromise
                        .then(function (items) {
                            scope.items = items;
                        })
                        .catch(function (reason) {
                            scope.items = [];
                        });

                    scope.setValue = function (value) {
                        ngModelCtrl.$setViewValue(value);
                    };

                }
            };
        });

})();
