(function() {
    'use strict';

    angular
        .module('awizApp')
        .config(function ($stateProvider) {
            $stateProvider.state('register', {
                parent: 'account',
                url: '/register',
                data: {
                    authorities: [],
                    pageTitle: 'register.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/register/register.html',
                        controller: 'RegisterController',
                        controllerAs: 'vm'
                    }
                }
            });
        });

})();
