(function () {
    'use strict';

    angular
        .module('awizApp')
        .directive('tableInvisibleOnLoading', function ($transitions) {
            return {
                restrict: 'A',
                link: function ($scope, $element) {
                    var onBefore = $transitions.onBefore({}, function () {
                        $element.hide();
                    });

                    var onFinish = $transitions.onFinish({}, function () {
                        $element.show();
                    });

                    var onError = $transitions.onError({}, function () {
                        $element.show();
                    });
                    $scope.$on('$destroy', function () {
                        onBefore();
                        onFinish();
                        onError();
                    });
                }
            };
        })
        .component('tableLoadingSpinner', {
            template: '<div class="table-loading-spinner" ng-if="$ctrl.isLoading"><i class="fas fa-spinner fa-spin fa-lg fa-2x"></i></div>',
            bindings: {
                items: '<'
            },
            controller: function ($transitions) {

                this.isLoading = false;

                var onBefore = $transitions.onBefore({}, function (transition) {
                    if (transition.from() === transition.to()) {
                        this.isLoading = true;
                    }
                }.bind(this));

                var onFinish = $transitions.onFinish({}, function (transition) {
                    if (transition.from() === transition.to()) {
                        this.isLoading = false;
                    }
                }.bind(this));

                var onError = $transitions.onError({}, function (transition) {
                    if (transition.from() === transition.to()) {
                        this.isLoading = false;
                    }
                }.bind(this));

                this.$onDestroy = function () {
                    onBefore();
                    onFinish();
                    onError();
                };
            }
        });

})();
