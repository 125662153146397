(function() {
    'use strict';

    angular
        .module('awizApp')
        .component("periodTimezoneFormat", {
            template: '{{$ctrl.startTime | amTimezone: $ctrl.timezone | amDateFormat : $ctrl.format1 }} - {{$ctrl.endTime | amTimezone: $ctrl.timezone | amDateFormat : $ctrl.format2 }}' +
                '<span style="font-size: xx-small; font-weight: normal; color: black;" ng-if="$ctrl.isDifferentTimezone()">' +
                '{{$ctrl.endTime | amTimezone: $ctrl.timezone | amDateFormat : $ctrl.format3}}' +
                '</span>',
            bindings: {
                startTime: '<',
                endTime: '<',
                timezone: '<'
            },
            controller: function ($translate) {
                var ctrl = this;
                var timezone = moment.tz.guess();
                ctrl.isDifferentTimezone = function () {
                    return timezone !== ctrl.timezone;
                };

                ctrl.format1 = 'YYYY-MM-DD [ ' + $translate.instant('timezone.hour') + ' ] HH:mm';
                ctrl.format2 = "HH:mm";
                ctrl.format3 = " (Z)";
            }
        });

})();
