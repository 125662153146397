(function () {
    'use strict';

    angular
        .module('awizApp')
        .directive('mySlot', function ($rootScope, $translate, $compile, $state, $interval, $http, EntryReservation, uiCalendarConfig, EntryReservationService, Principal, EntryReservationUtils, $cookies) {
            return {
                restrict: 'AE',
                require: '?slot',
                scope: {
                    slot: '=',
                    stickToCurrentDay: '=',
                    requestParams: '=',
                    defaultView: '@'
                },
                replace: true,
                templateUrl: "app/entities/entry-reservation/slot/slot.html",
                link: function (scope) {
                    Principal.isCompanyWorker(scope.slot.customer.id)
                        .then(function (result) {
                            scope.isSlotOwner = result;
                            scope.calendarOptions.calendar.selectOverlap = result;
                        });

                    var slotTimezone = scope.slot.timezone;
                    var clientTimezone = moment.tz.guess();
                    scope.isDifferentTimezone = function () {
                        return scope.slot.timezone !== clientTimezone;
                    };

                    scope.calendarId = 'slotCalendar-' + scope.slot.id;

                    scope.viewToSet = scope.defaultView || $cookies.get('view.' + scope.calendarId) || 'agendaDay';

                    scope.calendarOptions = {
                        calendar: {
                            allDaySlot: false,
                            header: {
                                left: 'agendaDay,listDay',
                                center: 'title',
                                right: 'today,prev,next'
                            },
                            views: {
                                listDay: {
                                    startParam: 'startDate',
                                    endParam: 'endDate'
                                }
                            },
                            defaultView: scope.viewToSet,
                            height: 'auto',
                            locale: $translate.use(),
                            timezone: slotTimezone,
                            timeFormat: 'H:mm',
                            nowIndicator: true,
                            now: now,
                            slotDuration: '00:15:00',
                            slotLabelInterval: '00:30:00',
                            slotLabelFormat: "H:mm",
                            selectable: true,
                            eventSources: [
                                {
                                    events: fetchEntries
                                },
                                {
                                    events: fetchReservedTime
                                }
                            ],
                            eventClick: eventClick,
                            selectAllow: selectAllow,
                            selectOverlap: false,
                            select: select,
                            editable: false,
                            eventDurationEditable: false,
                            eventRender: EntryReservationUtils.calendarEventRender,
                            viewRender: function (view) {
                                if (!scope.defaultView){
                                    $cookies.put('view.' + scope.calendarId, view.name);
                                }
                            }
                        }
                    };

                    function now() {
                        return moment().tz(slotTimezone);
                    }

                    function fetchEntries(start, end, timezone, callback) {
                        var params = scope.requestParams || {};
                        var startZoned = moment.tz(start.format(), timezone);
                        var endZoned = moment.tz(end.format(), timezone);
                        EntryReservationUtils.fetchSlotEntries(scope.slot.id, startZoned, endZoned, timezone, params, callback);
                    }

                    function fetchReservedTime(start, end, timezone, callback) {
                        var startZoned = moment.tz(start.format(), timezone);
                        var endZoned = moment.tz(end.format(), timezone);
                        EntryReservationUtils.fetchSlotReservedTime(scope.slot.id, startZoned, endZoned, timezone, callback);
                    }

                    function selectAllow(selectInfo) {
                        return EntryReservationUtils.calendarSelectAllow(selectInfo, scope.isSlotOwner);
                    }

                    function select(startTime, end) {
                        var startTimeZoned = moment.tz(startTime.format(), slotTimezone);
                        EntryReservationService.add({slotId: scope.slot.id, startTime: startTimeZoned});
                    }

                    function eventClick(event) {
                        if (event.id){
                            EntryReservationService.show(event.id);
                        }
                    }

                    function refetchCalendarEvents() {
                        var calendarObject = uiCalendarConfig.calendars[scope.calendarId];
                        if (calendarObject) {
                            calendarObject.fullCalendar('refetchEvents');

                            if (scope.stickToCurrentDay === true){
                                calendarObject.fullCalendar('gotoDate', moment());
                            }
                        }
                    }

                    scope.$on('entry:updated', function () {
                        refetchCalendarEvents();
                    });

                    var intervalPromise;

                    function initAutoRefresh() {
                        $interval.cancel(intervalPromise);
                        refetchCalendarEvents();
                        intervalPromise = $interval(initAutoRefresh, 30000);
                    }

                    initAutoRefresh();
                    scope.$on('$destroy', function () {
                        $interval.cancel(intervalPromise);
                    });

                }
            };
        });

})();
