(function() {
    'use strict';

    angular
        .module('awizApp')
        .factory('translationStorageProvider', function ($cookies, $log, LANGUAGES) {
            return {
                get: get,
                put: put
            };

            function get(name) {
                if (LANGUAGES.indexOf($cookies.get(name)) === -1) {
                    // $log.info('Resetting invalid cookie language "' + $cookies.get(name) + '" to prefered language "pl"');
                    // $cookies.put(name, 'pl');
                }
                return $cookies.get(name);
            }

            function put(name, value) {
                $cookies.put(name, value);
            }
        });

})();
