(function() {
    'use strict';

    angular
        .module('awizApp')
        .directive('uiSrefIf', function($compile) {
            return {
                restrict: 'A',
                link: function (scope, elem, attrs) {
                    elem.removeAttr('ui-sref-if');
                    $compile(elem)(scope);

                    scope.$watch(attrs.condition, function(bool) {
                        if (bool) {
                            elem.attr('ui-sref', attrs.value);
                        } else {
                            elem.removeAttr('ui-sref');
                            elem.removeAttr('href');
                        }

                        $compile(elem)(scope);
                    });
                }
            };
        });

})();
