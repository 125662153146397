(function () {
    'use strict';

    angular
        .module('awizApp')
        .component('users', {
            bindings: {
                filters: '=',
                page: '=',
                currentAccount: '<'
            },
            templateUrl: 'app/user/user.html',
            controller: function ($state, UserService) {
                this.add = function(){
                    UserService.add().then(reload);
                };
                this.edit = function(id){
                    UserService.edit(id).then(reload);
                };
                this.delete = function (item) {
                    UserService.delete(item.id, item).then(reload);
                };
                this.submit = function () {
                    $state.go($state.current, this.filters);
                };
                function reload() {
                    $state.reload();
                }
            }
        })
        .config(function ($stateProvider) {
            $stateProvider
                .state('user', {
                    parent: 'entity',
                    url: '/user?page&sort&search&size',
                    data: {
                        authorities: ['ROLE_USER'],
                        pageTitle: 'user.title'
                    },
                    views: {
                        'content@': 'users'
                    },
                    params: {
                        page: {
                            value: '1',
                            squash: true
                        },
                        size: {
                            value: '50',
                            squash: true
                        },
                        sort: {
                            value: 'id,asc',
                            squash: true
                        },
                        search: null
                    },
                    resolve: {
                        currentAccount: function(Principal){
                            return Principal.identity();
                        },
                        filters: function ($transition$) {
                            return angular.copy($transition$.params());
                        },
                        page: function (CompanyUser, $q, $stateParams) {
                            var deferred = $q.defer();
                            var queryParams = angular.copy($stateParams);
                            queryParams.page -= 1;
                            CompanyUser.query(queryParams, onSuccess, onError);

                            function onSuccess(response, headers) {
                                deferred.resolve({
                                    items: response,
                                    itemsCount: headers('X-Total-Count')
                                });
                            }

                            function onError() {
                                deferred.reject();
                            }

                            return deferred.promise;
                        }
                    }
                });

        });

})();
