(function () {
    'use strict';

    angular
        .module('awizApp')
        .controller('ForwarderInviteDialogController', function ($translate, $scope, $uibModalInstance, Forwarder) {
            var vm = this;

            vm.invitation = {
                language: $translate.use()
            };

            vm.save = function () {
                return Forwarder.invitationRequest(vm.invitation, onSaveSuccess);

                function onSaveSuccess(result) {
                    $scope.$emit('forwarder:updated', result);
                    $uibModalInstance.close(result);
                }
            };

            vm.close = function () {
                $uibModalInstance.dismiss('cancel');
            };
        });

})();
