(function() {
    'use strict';

    angular
        .module('awizApp')
        .config(function ($stateProvider) {
            $stateProvider
                .state('error', {
                    parent: 'app',
                    url: '/error',
                    data: {
                        authorities: [],
                        pageTitle: 'error.title'
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/layouts/error/error.html'
                        }
                    }
                })
                .state('accessdenied', {
                    parent: 'app',
                    url: '/accessdenied',
                    data: {
                        authorities: []
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/layouts/error/accessdenied.html'
                        }
                    }
                });
        });

})();
