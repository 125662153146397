(function () {
    'use strict';

    angular
        .module('awizApp')
        .directive('mySlotsWidget', function ($rootScope, $http, EntryReservation) {
            return {
                restrict: 'AE',
                scope: {},
                templateUrl: "app/entities/entry-reservation/widget-slots/slots.html",
                link: function (scope) {
                    EntryReservation.mySlots().$promise.then(function (result) {
                        scope.slots = result;
                    });
                }
            };
        });

})();
