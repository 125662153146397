(function () {
    'use strict';

    angular
        .module('awizApp')
        .component('forwarders', {
            bindings: {
                filters: '=',
                page: '='
            },
            templateUrl: 'app/entities/forwarder/list.html',
            controller: function ($state, ConfirmDialogService, Forwarder) {
                this.submit = function () {
                    $state.go($state.current, this.filters);
                };

                this.delete = function (item) {
                    return ConfirmDialogService
                        .open({
                            messageTranslated: {
                                key: "entity.delete.question",
                                params: {param: item.code}
                            },
                            confirmPromise: function () {
                                return Forwarder.delete({id: item.id}).$promise;
                            }
                        })
                        .then(function () {
                            $state.reload();
                        });
                };
            }
        })
        .config(function ($stateProvider) {
            $stateProvider
                .state('forwarder', {
                    parent: 'entity',
                    url: '/forwarder?page&size&sort&search',
                    data: {
                        authorities: ['ROLE_USER']
                    },
                    views: {
                        'content@': 'forwarders'
                    },
                    params: {
                        page: {
                            value: '1',
                            squash: true
                        },
                        sort: {
                            value: 'id,desc',
                            squash: true
                        },
                        size: {
                            value: '50',
                            squash: true
                        },
                        search: null
                    },
                    resolve: {
                        filters: function ($transition$) {
                            return angular.copy($transition$.params());
                        },
                        page: function (Forwarder, $q, $stateParams) {
                            var deferred = $q.defer();
                            var queryParams = angular.copy($stateParams);
                            queryParams.page -= 1;
                            Forwarder.query(queryParams, onSuccess, onError);

                            function onSuccess(response, headers) {
                                deferred.resolve({
                                    items: response,
                                    itemsCount: headers('X-Total-Count')
                                });
                            }

                            function onError() {
                                deferred.reject();
                            }

                            return deferred.promise;
                        }
                    }
                })
                .state('forwarder.invitations', {
                    data: {
                        authorities: ['ROLE_OWNER']
                    },
                    onEnter: function ($transition$, $uibModal, ModalUtil) {
                        $uibModal.open({
                            templateUrl: 'app/entities/forwarder/invitations-dialog.html',
                            controller: 'ForwarderInvitationsDialogController',
                            controllerAs: 'vm',
                            backdrop: 'static',
                            size: 'md'
                        }).result
                            .then(function () {
                                ModalUtil.defaultThen($transition$.from(), $transition$.params('from'));
                            })
                            .catch(function () {
                                ModalUtil.defaultCatch($transition$.from(), $transition$.params('from'));
                            });
                    },
                    onExit: function ($uibModalStack) {
                        $uibModalStack.dismissAll();
                    }
                })
                .state('forwarder.invite', {
                    data: {
                        authorities: ['ROLE_OWNER']
                    },
                    onEnter: function ($transition$, $uibModal, ModalUtil) {
                        $uibModal.open({
                            templateUrl: 'app/entities/forwarder/invite-dialog.html',
                            controller: 'ForwarderInviteDialogController',
                            controllerAs: 'vm',
                            backdrop: 'static',
                            size: 'md'
                        }).result
                            .then(function () {
                                ModalUtil.defaultThen($transition$.from(), $transition$.params('from'));
                            })
                            .catch(function () {
                                ModalUtil.defaultCatch($transition$.from(), $transition$.params('from'));
                            });
                    },
                    onExit: function ($uibModalStack) {
                        $uibModalStack.dismissAll();
                    }
                })
                .state('forwarderInviteFinish', {
                    parent: 'entity',
                    url: '/forwarder/finish?key',
                    data: {
                        authorities: []
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/forwarder/invite-finish.html',
                            controller: 'ForwarderInviteFinishController',
                            controllerAs: 'vm'
                        }
                    }
                })
            ;
        });

})();
