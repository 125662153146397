(function() {
    'use strict';

    angular
        .module('awizApp')
        .controller('SessionsController', function (Sessions, Principal) {
            var vm = this;

            vm.account = null;
            vm.error = null;
            vm.invalidate = function (series) {
                Sessions.delete({series: encodeURIComponent(series)},
                    function () {
                        vm.error = null;
                        vm.success = 'OK';
                        vm.sessions = Sessions.getAll();
                    },
                    function () {
                        vm.success = null;
                        vm.error = 'ERROR';
                    });
            };

            vm.sessions = Sessions.getAll();
            vm.success = null;

            Principal.identity().then(function(account) {
                vm.account = account;
            });

        });

})();
