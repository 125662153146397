(function () {
    'use strict';

    angular
        .module('awizApp')
        .component('reservations', {
            bindings: {
                filters: '=',
                page: '=',
                reservationStatuses: '<'
            },
            templateUrl: 'app/entities/entry-reservation/reservation-list/list.html',
            controller: function ($state, Principal, EntryReservationService, $scope) {
                this.submit = function () {
                    $state.go($state.current, this.filters);
                };
                this.show = EntryReservationService.show;
                this.hasSlots = Principal.hasSlots;

                $scope.$on('entry:updated', function () {
                    $state.reload();
                });
            }
        })
        .config(function ($stateProvider) {
            $stateProvider
                .state('entryreservation-list', {
                    parent: 'entity',
                    url: '/entry-reservation-list?{page:int}&{size:int}&sort&search&status&start&end&{shipper:int}&{slot:int}',
                    data: {
                        authorities: ['ROLE_USER']
                    },
                    views: {
                        'content@': "reservations"
                    },
                    params: {
                        page: {
                            value: 1,
                            squash: true
                        },
                        sort: {
                            value: 'id,desc',
                            squash: true
                        },
                        size: {
                            value: 50,
                            squash: true
                        },
                        search: null,
                        status: {
                            array: true
                        }
                    },
                    resolve: {
                        reservationStatuses: function (EntryReservation) {
                            return EntryReservation.statuses().$promise;
                        },
                        filters: function ($transition$) {
                            return angular.copy($transition$.params());
                        },
                        page: function (EntryReservation, $q, $stateParams) {
                            var deferred = $q.defer();
                            var queryParams = angular.copy($stateParams);
                            queryParams.page -= 1;
                            EntryReservation.query(queryParams, onSuccess, onError);

                            function onSuccess(response, headers) {
                                deferred.resolve({
                                    items: response,
                                    itemsCount: headers('X-Total-Count')
                                });
                            }

                            function onError() {
                                deferred.reject();
                            }

                            return deferred.promise;
                        }
                    }
                })
                .state('entryreservation-calendar', {
                    parent: 'entity',
                    url: '/entry-reservation-calendar?search&status&{shipper:int}&{slot:int}',
                    data: {
                        authorities: ['ROLE_USER']
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/entities/entry-reservation/reservation-calendar/calendar.html',
                            controller: 'EntryReservationCalendarController',
                            controllerAs: 'vm'
                        }
                    },
                    params: {
                        search: null,
                        status: {
                            array: true
                        }
                    }
                })
                .state('home.entryreservation-link', {
                    url: 'reservation/{entryId}',
                    data: {
                        authorities: ['ROLE_USER']
                    },
                    onEnter: function ($stateParams, $transition$, EntryReservationService, ModalUtil) {
                        EntryReservationService.show($stateParams.entryId)
                            .then(function () {
                                ModalUtil.defaultThen($transition$.from(), $transition$.params('from'));
                            })
                            .catch(function () {
                                ModalUtil.defaultCatch($transition$.from(), $transition$.params('from'));
                            });
                    },
                    onExit: function ($uibModalStack) {
                        $uibModalStack.dismissAll();
                    }
                })
                .state('entryreservation-show', {
                    data: {
                        authorities: ['ROLE_USER']
                    },
                    params: {
                        id: null
                    },
                    onEnter: function ($stateParams, $transition$, EntryReservationService, ModalUtil) {
                        EntryReservationService.show($stateParams.id)
                            .then(function () {
                                ModalUtil.defaultThen($transition$.from(), $transition$.params('from'));
                            })
                            .catch(function () {
                                ModalUtil.defaultCatch($transition$.from(), $transition$.params('from'));
                            });
                    },
                    onExit: function ($uibModalStack) {
                        $uibModalStack.dismissAll();
                    }
                })

            ;
        });

})();
