(function () {
    'use strict';

    angular
        .module('awizApp')
        .config(function ($localStorageProvider) {
            $localStorageProvider.setKeyPrefix('jhi-');
        });

})();
