(function () {
    'use strict';

    angular
        .module('awizApp')
        .factory('Slot', function ($resource) {
            return $resource('api/slot/:id', {}, {
                'query': {method: 'GET', isArray: true},
                'update': {method: 'PUT'},
                'autocomplete': {method: 'GET', url: 'api/slot-autocomplete', isArray: true}
            });
        })
        .factory('SlotSchedule', function ($resource) {
            return $resource('api/slot-schedule/:id', {}, {
                'update': {method: 'PUT'},
                'schedule': {method: 'GET', url: 'api/slot/:id/schedule', isArray: true}
            });
        });

})();
