(function () {
    'use strict';
    angular
        .module('awizApp')
        .factory('OrganizationSettings', function ($resource) {
            return $resource('api/organization-settings/:id', {}, {
                'query': {method: 'GET', isArray: true},
                'updatePrivacyPolicy': {
                    method: 'PUT',
                    transformRequest: function (data) {
                        var fd = new FormData();
                        angular.forEach(data, function(value, key) {
                            fd.append(key, value);
                        });
                        return fd;
                    },
                    url: "api/privacy-policy",
                    headers: {'Content-Type': undefined, enctype: 'multipart/form-data'}
                }
            });
        });

})();
