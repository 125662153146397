(function () {
    'use strict';

    angular
        .module('awizApp')
        .factory('Authority', function ($resource) {
            return $resource('api/authorities', {}, {
                'query': {method: 'GET', isArray: true}
            });
        });

})();
