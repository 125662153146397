(function () {
    'use strict';

    angular
        .module('awizApp')
        .directive('entryOptionButton', function ($rootScope, $state, $http, EntryReservation, EntryReservationService) {
            return {
                restrict: 'AE',
                require: '?entry',
                scope: {
                    entry: '='
                },
                replace: true,
                templateUrl: "app/entities/entry-reservation/option-button/option-button.html",
                link: function (scope) {

                    scope.confirm = function () {
                        EntryReservationService.statusChange(scope.entry.id, 'CONFIRM');
                    };

                    scope.backToUnconfirmed = function () {
                        EntryReservationService.statusChange(scope.entry.id, 'UNCONFIRM', true);
                    };

                    scope.finish = function () {
                        EntryReservationService.statusChange(scope.entry.id, 'FINISH');
                    };

                    scope.backToConfirmed = function () {
                        EntryReservationService.statusChange(scope.entry.id, 'CONFIRM', true);
                    };

                    scope.cancel = function () {
                        EntryReservationService.statusChange(scope.entry.id, 'CANCEL', true);
                    };

                    scope.edit = function () {
                        EntryReservationService.edit(scope.entry.id);
                    };

                    scope.timeChange = function () {
                        EntryReservationService.timeChange(scope.entry.id);
                    };
                    scope.carrierChange = function () {
                        EntryReservationService.carrierChange(scope.entry.id);
                    };

                    scope.toggleWorkStandBy = function (workStandBy) {
                        EntryReservationService.toggleWorkStandBy(scope.entry.id, workStandBy);
                    };

                    scope.isUnconfirmed = function () {
                        return scope.entry && scope.entry.status === 'UNCONFIRMED';
                    };

                    scope.isConfirmed = function () {
                        return scope.entry && scope.entry.status === 'CONFIRMED';
                    };

                    scope.isFinished = function () {
                        var finishedStatuses = ['PROCESSED', 'PROCESSED_DELAYED', 'UNPROCESSED_DID_NOT_ARRIVE', 'CANCELLED'];
                        return scope.entry && finishedStatuses.includes(scope.entry.status);
                    };

                    scope.isReadyForWork = function () {
                        return scope.entry && scope.entry.workStandBy;
                    };

                }
            };
        });

})();
