(function () {
    'use strict';

    angular
        .module('awizApp')
        .directive('fileUpload', function () {
            return {
                scope: true,        //create a new scope
                link: function (scope, el, attrs) {
                    el.bind('change', function (event) {
                        var files = event.target.files;
                        //iterate files since 'multiple' may be specified on the element
                        for (var i = 0; i < files.length; i++) {
                            //emit event upward
                            scope.$emit("fileSelected", {file: files[i]});
                        }
                    });
                }
            };
        })
        .factory('AccountCustomer', function ($resource) {
            return $resource('api/account-customer/:id', {}, {
                'query': {method: 'GET', isArray: true},
                'update': {method: 'PUT'},
                'autocomplete': {url: 'api/customer-autocomplete', isArray: true}
            });
        })
        .controller('CompanyController', function ($scope, Principal, AccountCustomer, OrganizationSettings) {
            var vm = this;

            Principal.identity().then(function (account) {
                vm.item = angular.copy(account.customer);
            });

            vm.hasSlots = Principal.hasSlots;

            vm.loadOrganizationSettings = function () {
                OrganizationSettings.get().$promise.then(function (data) {
                    vm.organizationSettings = data;
                });
            };

            vm.saveOrganization = function () {
                return AccountCustomer.update(vm.item).$promise.then(function () {
                    Principal.identity(true).then(function (account) {
                        vm.item = angular.copy(account.customer);
                    });
                });
            };


            $scope.$on("fileSelected", function (event, args) {
                $scope.$apply(function () {
                    //add the file object to the scope's files collection
                    vm.newPrivacyPolicy = {
                        file : args.file
                    };
                });
            });

            vm.saveOrganizationSettings = function () {
                return OrganizationSettings.updatePrivacyPolicy({}, vm.newPrivacyPolicy).$promise.then(function () {
                    delete vm.newPrivacyPolicy;
                    vm.loadOrganizationSettings();
                });
            };
        });

})();
