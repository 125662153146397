(function() {
    'use strict';

    angular
        .module('awizApp')
        .component('jhiItemCount', {
            template: '<div class="info">' +
                '{{(($ctrl.page-1) * ($ctrl.pageSize || 50))==0 ? 1:(($ctrl.page-1) * ($ctrl.pageSize || 50))}} - ' +
                '{{($ctrl.page * ($ctrl.pageSize || 50)) < $ctrl.queryCount ? ($ctrl.page * ($ctrl.pageSize || 50)) : $ctrl.queryCount}} ' +
                'z {{$ctrl.queryCount}} rekordów' +
                '</div>',
            bindings: {
                page: '<',
                queryCount: '<total',
                pageSize: '<pagesize'
            }
        });
})();
