(function() {
    'use strict';

    angular
        .module('awizApp')
        .controller('LogsController', function (LogsService) {
            var vm = this;

            vm.changeLevel = function (name, level) {
                LogsService.changeLevel({name: name, level: level}, function () {
                    vm.loggers = LogsService.findAll();
                });
            };
            vm.loggers = LogsService.findAll();

        });

})();
