(function () {
    'use strict';

    angular
        .module('awizApp')
        .directive('principalEmail', function (Principal) {
            return {
                restrict: 'AE',
                template: "<span class='bold'>{{currentAccount.email}}</span>",
                link: function (scope) {

                    scope.$watch(function () {
                        return Principal.isAuthenticated();
                    }, function () {

                        Principal.identity().then(function (account) {
                            if (account)
                                scope.currentAccount = angular.copy(account);
                            else {
                                scope.currentAccount = {
                                    email: "..."
                                };
                            }
                        });
                    });

                }
            };

        });

})();
