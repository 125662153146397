(function () {
    'use strict';

    angular
        .module('awizApp')
        .controller('EntryReservationCalendarController', function ($scope, $translate, $interval, EntryReservation, uiCalendarConfig, EntryReservationService, EntryReservationUtils, Principal) {
            var vm = this;
            vm.tableFilters = {};
            vm.hasSlots = Principal.hasSlots;
            vm.timezone = moment.tz.guess();

            EntryReservation.statuses().$promise.then(function (data) {
                vm.reservationStatuses = data;
            });

            vm.calendarId = 'reservationsCalendar';
            vm.calendarOptions = {
                calendar: {
                    allDaySlot: false,
                    header: {
                        left: 'agendaWeek,month',
                        center: 'title',
                        right: 'today,prev,next'
                    },
                    defaultView: 'month',
                    height: 'auto',
                    aspectRatio: 2.2,
                    locale: $translate.use(),
                    timezone: vm.timezone,
                    timeFormat: 'H:mm',
                    nowIndicator: true,
                    now: now,
                    slotDuration: '00:30:00',
                    slotLabelInterval: '00:60:00',
                    slotLabelFormat: "H:mm",
                    selectable: false,
                    eventSources: [
                        {
                            events: fetchEntries
                        }
                    ],
                    eventClick: eventClick,
                    selectOverlap: false,
                    editable: false,
                    eventDurationEditable: false,
                    eventRender: EntryReservationUtils.calendarEventRender
                }
            };

            function now() {
                return moment().tz(vm.timezone);
            }

            function fetchEntries(start, end, timezone, callback) {
                EntryReservationUtils.downloadTransformEntries(vm.tableFilters, start, end, timezone, callback);
            }

            function eventClick(event) {
                if (event.id){
                    EntryReservationService.show(event.id);
                }
            }

            function refetchCalendarEvents() {
                var calendarObject = uiCalendarConfig.calendars[vm.calendarId];
                if (calendarObject) {
                    calendarObject.fullCalendar('refetchEvents');
                }
            }

            $scope.$on('entry:updated', function () {
                refetchCalendarEvents();
            });

            var intervalPromise;
            function initAutoRefresh() {
                $interval.cancel(intervalPromise);
                refetchCalendarEvents();
                intervalPromise = $interval(initAutoRefresh, 30000);
            }

            initAutoRefresh();
            $scope.$on('$destroy', function () {
                $interval.cancel(intervalPromise);
            });

            vm.search = function(){
                refetchCalendarEvents();
            };
        });

})();
