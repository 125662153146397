(function () {
    'use strict';

    angular
        .module('awizApp')
        .component('loginComponent', {
            bindings: {
                returnTo: '<'
            },
            templateUrl: 'app/home/login.html',
            controller: function ($state, Auth) {
                var vm = this;
                this.formData = {
                    rememberMe: true
                };

                var returnToOriginalState = function () {
                    vm.authenticationError = false;
                    var state = vm.returnTo.state();
                    var params = vm.returnTo.params();
                    var options = Object.assign({}, vm.returnTo.options());
                    $state.go(state, params, options);
                };

                this.login = function (event) {
                    event.preventDefault();
                    Auth.login(this.formData)
                        .then(returnToOriginalState)
                        .catch(function () {
                            vm.authenticationError = true;
                        });
                };
            }
        })
        .config(function ($stateProvider) {
            $stateProvider.state('login', {
                parent: 'app',
                url: '/login',
                views: {
                    'content@': 'loginComponent'
                },
                resolve: {
                    returnTo: function ($transition$) {
                        if ($transition$.redirectedFrom() != null) {
                            // The user was redirected to the login state (e.g., via the requiresAuth hook when trying to activate contacts)
                            // Return to the original attempted target state (e.g., contacts)
                            return $transition$.redirectedFrom().targetState();
                        }
                        var $state = $transition$.router.stateService;

                        // The user was not redirected to the login state; they directly activated the login state somehow.
                        // Return them to the state they came from.
                        if ($transition$.from().name !== '') {
                            return $state.target($transition$.from(), $transition$.params("from"));
                        }

                        // If the fromState's name is empty, then this was the initial transition. Just return them to the home state
                        return $state.target('home');
                    }
                }
            });

        });

})();
