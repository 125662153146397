(function() {
    'use strict';

    angular
        .module('awizApp')
        .config(function ($stateProvider) {
            $stateProvider.state('password', {
                parent: 'account',
                url: '/password',
                data: {
                    authorities: [],
                    pageTitle: 'password.page-title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/password/password.html',
                        controller: 'PasswordController',
                        controllerAs: 'vm'
                    }
                }
            });
        });

})();
