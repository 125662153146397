(function() {
    'use strict';

    angular
        .module('awizApp')
        .controller('EntryReservationDetailController', function ($timeout, $rootScope, $scope, entryId, $uibModalInstance, EntryReservation, SmsService) {
            var vm = this;
            vm.entryId = entryId;

            function loadEntry() {
                EntryReservation.get({id: vm.entryId}).$promise.then(function (data) {
                    vm.item = data;
                });
            }
            loadEntry();

            $scope.$on('entry:updated', function () {
                loadEntry();
            });

            vm.close = function() {
                $uibModalInstance.dismiss('cancel');
            };

            vm.sendSMS = function (phone) {
                SmsService.sendMessage(phone);
            };
        });

})();
