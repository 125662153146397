(function() {
    'use strict';

    angular
        .module('awizApp')
        .factory('Password', function ($resource) {
            return $resource('api/account/change_password', {}, {});
        });

})();
