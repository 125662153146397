(function() {
    'use strict';

    angular
        .module('awizApp')
        .component('settings', {
            bindings: {
                settingsAccount: '<'
            },
            templateUrl: 'app/account/settings/settings.html',
            controller: function ($translate, Auth, Principal) {
                this.save = function () {
                    Auth.updateAccount(this.settingsAccount).then(function() {
                        Principal.identity(true).then(function(account) {
                            $translate.use(account.language);
                        });
                    });
                };
            }
        })
        .config(function ($stateProvider) {
            $stateProvider.state('settings', {
                parent: 'account',
                url: '/settings',
                data: {
                    authorities: ['ROLE_USER']
                },
                views: {
                    'content@': 'settings'
                },
                resolve: {
                    settingsAccount: function (Principal) {
                        return Principal.identity().then(function(account) {
                            return {
                                firstName: account.firstName,
                                lastName: account.lastName,
                                email: account.email,
                                language: account.language
                            };
                        });
                    }
                }
            });
        });

})();
