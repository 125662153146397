(function () {
    'use strict';

    angular
        .module('awizApp')
        .directive('isCompanyWorker', function (Principal) {
            return {
                restrict: 'A',
                scope: {
                    customerId: "="
                },
                link: function (scope, element, attrs) {

                    var setVisible = function () {
                            element.removeClass('hidden');
                        },
                        setHidden = function () {
                            element.addClass('hidden');
                        },
                        defineVisibility = function (reset) {

                            if (reset) {
                                setVisible();
                            }

                            Principal.isCompanyWorker(scope.customerId)
                                .then(function (result) {
                                    if (result) {
                                        setVisible();
                                    } else {
                                        setHidden();
                                    }
                                });
                        };

                    defineVisibility(true);

                    scope.$watch(function () {
                        return Principal.isAuthenticated();
                    }, function () {
                        defineVisibility(true);
                    });
                }
            };

        });

})();
