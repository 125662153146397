(function() {
    'use strict';

    angular
        .module('awizApp')
        .factory('errorHandlerInterceptor', function ($q, $rootScope) {
            return {
                responseError: responseError
            };

            function responseError (response) {
                if (!(response.status === 401 && (response.data === '' || (response.data.path && response.data.path.indexOf('/api/account') === 0 )))) {
                    $rootScope.$emit('awizApp.httpError', response);
                }

                return $q.reject(response);
            }
        });

})();
