(function() {
    'use strict';

    angular
        .module('awizApp')
        .factory('Account', function ($resource) {
            return $resource('api/account', {}, {
                'get': { method: 'GET', params: {}, isArray: false }
            });
        });

})();
