(function() {
    'use strict';

    angular
        .module('awizApp')
        .config(function ($stateProvider) {
            $stateProvider.state('company', {
                parent: 'account',
                url: '/company',
                data: {
                    authorities: ['ROLE_OWNER'],
                    pageTitle: 'user.organization-settings'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/company/company.html',
                        controller: 'CompanyController',
                        controllerAs: 'vm'
                    }
                }
            });
        });

})();
