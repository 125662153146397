(function () {
    'use strict';

    angular
        .module('awizApp')
        .service('UserService', function ($translate, $uibModal, ConfirmDialogService, CompanyUser) {
            this.add = function () {
                return $uibModal.open({
                    templateUrl: 'app/user/user-dialog.html',
                    controller: 'UserDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        user: function () {
                            return {
                                id: null,
                                activated: true,
                                roles : [ 'ROLE_USER' ],
                                sendEmailConfirmation: true,
                                language: $translate.use()
                            };
                        }
                    }
                }).result;
            };
            this.edit = function (id) {
                return $uibModal.open({
                    templateUrl: 'app/user/user-dialog.html',
                    controller: 'UserDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        user: function () {
                            return CompanyUser.get({id : id}).$promise.then(function (data) {
                                return {
                                    id: data.id,
                                    roles: data.roles,
                                    email: data.email,
                                    firstName: data.firstName,
                                    lastName: data.lastName,
                                    activated: data.activated,
                                    language: data.language
                                };
                            });
                        }
                    }
                }).result;
            };
            this.delete = function (id, item) {
                return ConfirmDialogService.open({
                    messageTranslated: {
                        key: "entity.delete.question",
                        params: {param: item.email}
                    },
                    confirmPromise: function () {
                        return CompanyUser.delete({id: id}).$promise;
                    }
                });
            };
        })
        .factory('User', function ($resource) {
            return $resource('api/admin/users/:login:id', {}, {
                'query': {method: 'GET', isArray: true},
                'get': {
                    method: 'GET',
                    transformResponse: function (data) {
                        data = angular.fromJson(data);
                        return data;
                    }
                },
                'save': {method: 'POST'},
                'update': {method: 'PUT'},
                'delete': {method: 'DELETE'}
            });
        });

})();
