(function() {
    'use strict';

    angular
        .module('awizApp')
        .config(function ($urlRouterProvider, $httpProvider, httpRequestInterceptorCacheBusterProvider, $urlMatcherFactoryProvider) {

            //enable CSRF
            $httpProvider.defaults.xsrfCookieName = 'CSRF-TOKEN';
            $httpProvider.defaults.xsrfHeaderName = 'X-CSRF-TOKEN';

            //Cache everything except rest api requests
            httpRequestInterceptorCacheBusterProvider.setMatchlist([/.*api.*/, /.*protected.*/], true);

            $urlRouterProvider.otherwise('/');

            $httpProvider.interceptors.push('errorHandlerInterceptor');
            $httpProvider.interceptors.push('authExpiredInterceptor');
            $httpProvider.interceptors.push('notificationInterceptor');

            $urlMatcherFactoryProvider.type('boolean', {
                name : 'boolean',
                decode: function(val) { return val === true || val === 'true'; },
                encode: function(val) { return val ? 1 : 0; },
                equals: function(a, b) { return this.is(a) && a === b; },
                is: function(val) { return [true,false,0,1].indexOf(val) >= 0; },
                pattern: /bool|true|0|1/
            });
        });

})();
