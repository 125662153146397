(function () {
    'use strict';

    angular
        .module('awizApp')
        .config(function ($translateProvider, tmhDynamicLocaleProvider) {
            // Initialize angular-translate
            $translateProvider.preferredLanguage('pl');
            $translateProvider.registerAvailableLanguageKeys([
                'en',
                'pl'
            ], {
                'en': 'en',
                'en_*': 'en',
                'en-*': 'en',
                'pl': 'pl',
                'pl_*': 'pl',
                'pl-*': 'pl'
            });
            $translateProvider.determinePreferredLanguage();
            $translateProvider.useStorage('translationStorageProvider');
            $translateProvider.useSanitizeValueStrategy('escaped');
            $translateProvider.addInterpolation('$translateMessageFormatInterpolation');
            $translateProvider.useLoader('$translatePartialLoader', {
                urlTemplate: 'i18n/{lang}/{part}.json'
            });

            tmhDynamicLocaleProvider.localeLocationPattern('i18n/angular-locale_{{locale}}.js');
            tmhDynamicLocaleProvider.useStorage('translationStorageProvider');
            tmhDynamicLocaleProvider.storageKey('NG_TRANSLATE_LANG_KEY');
        });

})();
