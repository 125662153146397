(function () {
    'use strict';

    angular
        .module('awizApp')
        .directive('focusElement', function ($timeout) {
            return {
                restrict: 'A',
                scope: false,
                link: function (scope, element) {
                    $timeout(function () {
                        element.focus();
                    }, 100);
                }
            };
        });
}());

