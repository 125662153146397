(function () {
    'use strict';

    angular
        .module('awizApp')
        .config(function ($stateProvider) {
            $stateProvider.state('app', {
                abstract: true,
                views: {
                    'navbar@': {
                        templateUrl: 'app/layouts/navbar/navbar.html',
                        controller: 'NavbarController',
                        controllerAs: 'vm'
                    },
                    'sidebar@': {
                        templateUrl: 'app/layouts/sidebar/sidebar.html',
                        controller: 'SidebarController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('settings');
                        $translatePartialLoader.addPart('slot-management');
                        $translatePartialLoader.addPart('user-management');
                        $translate.refresh();
                    }
                }
            });
        })
        // .config(function ($stateProvider) {
        //     $stateProvider.decorator('onEnter', function (state, parent) {
        //         // this is original, by configuration created parent
        //         var result = parent(state);
        //         console.log('onEnter');
        //         // we can define where we do not want to change that default, configured
        //         // var skipState = state.name === "home"
        //         //     || state.name === "modala"
        //         //     // child already has that in parent
        //         //     || state.name.indexOf(".") > 0;
        //         //
        //         // // and return taht default
        //         // if (skipState) {
        //         //     return result;
        //         // }
        //         //
        //         // // FINALLY - HERE
        //         // //   we want to change parent, or even introduce new
        //         // state.parent = 'modala';
        //
        //         // and rebuild it
        //         // result = parent(state);
        //
        //         // new parent in place
        //         return result;
        //
        //     });
        // })
        // .config(function ($stateProvider) {
        //
        //     $stateProvider.decorator('parent', function (state, parent) {
        //         // this is original, by configuration created parent
        //         var result = parent(state);
        //
        //         // we can define where we do not want to change that default, configured
        //         var skipState = state.name === "home"
        //             || state.name === "modala"
        //             // child already has that in parent
        //             || state.name.indexOf(".") > 0;
        //
        //         // and return taht default
        //         if (skipState) {
        //             return result;
        //         }
        //
        //         // FINALLY - HERE
        //         //   we want to change parent, or even introduce new
        //         state.parent = 'modala';
        //
        //         // and rebuild it
        //         result = parent(state);
        //
        //         // new parent in place
        //         return result;
        //
        //     });
        // })
        //
        // .config(function ($stateProvider) {
        //     $stateProvider
        //         .state('modala', {
        //             template: '<div>' +
        //                 '<h1>MODALA in play</h1>' +
        //                 '<div ui-view=""></div>' +
        //                 '</div>'
        //         });
        //     }
        // )
    ;

})();
