(function() {
    'use strict';

    angular
        .module('awizApp')
        .directive('timeModelFormatter', function () {
            return {
                restrict: 'A',
                require: 'ngModel',
                link: function (scope, element, attrs, ngModel) {

                    ngModel.$formatters.push(function (value) {
                        if(value === null)
                            return;
                        return moment(value, "HH:mm:ss").toDate();
                    });

                    ngModel.$parsers.push(function (value) {
                        if (value === null)
                            return;

                        return moment(value).format('HH:mm:ss');
                    });
                }
            };
        })
        .directive('dateModelFormatter', function () {
            return {
                restrict: 'A',
                require: 'ngModel',
                link: function (scope, element, attrs, ngModel) {

                    ngModel.$formatters.push(function (value) {
                        if(value === null)
                            return;
                        return value;
                    });

                    ngModel.$parsers.push(function (value) {
                        if (value === null)
                            return;

                        return moment(value).format("YYYY-MM-DD");
                    });
                }
            };
        });

})();
