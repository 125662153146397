(function() {
    'use strict';

    angular
        .module('awizApp')
        .directive('selectDateRange', function (DateRangePickerConfig, $timeout) {
            return {
                scope: {
                    start: '=',
                    end: '=',
                    onChange: '&',
                    options: '='
                },
                template: '<div class="input-group input-group-sm">\n' +
                            '<span class="input-group-addon" translate-attr="{ title: selectOptions.placeholderKey }"><i class="fas fa-clock fa-fw"></i></span>' +
                            '<input date-range-picker options="daterangepicker_options" ' +
                                'class="form-control date-range-picker" type="text" translate-attr="{ placeholder: selectOptions.placeholderKey }"' +
                                'ng-change="onModelChange()" ng-model="period" />' +
                            '</div>',
                link: function (scope) {
                    scope.period = {};
                    if (scope.start) {
                        scope.period.startDate = scope.start;
                    }
                    if (scope.end) {
                        scope.period.endDate = scope.end;
                    }

                    scope.onModelChange = function () {
                        scope.start = scope.period.startDate ? moment(scope.period.startDate).toISOString() : null;
                        scope.end = scope.period.endDate ? moment(scope.period.endDate).toISOString() : null;
                        $timeout(function () {
                            scope.onChange();
                        });
                    };

                    var defaultOptions = {
                        placeholderKey: ''
                    };

                    scope.selectOptions = angular.extend({}, defaultOptions, scope.options);

                    var options = {
                        eventHandlers: {
                            'cancel.daterangepicker': function (ev, picker) {
                                scope.period = {};
                                scope.onModelChange();
                            },
                            'apply.daterangepicker': function (ev, picker) {
                                scope.onModelChange();
                            }
                        }
                    };
                    scope.daterangepicker_options = DateRangePickerConfig.get(options);
                }
            };
        })
        ;
})();
