(function() {
    'use strict';

    angular
        .module('awizApp')
        .factory('JhiHealthService', function ($rootScope, $http) {
            return {
                checkHealth: checkHealth
            };

            function checkHealth () {
                return $http.get('health').then(function (response) {
                    return response.data;
                });
            }
        });

})();
