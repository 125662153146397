(function() {
    'use strict';

    angular
        .module('awizApp')
        .config(function($stateProvider) {
            $stateProvider.state('logs', {
                parent: 'admin',
                url: '/logs',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'logs.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/admin/logs/logs.html',
                        controller: 'LogsController',
                        controllerAs: 'vm'
                    }
                }
            });
        });

})();
