(function () {
    'use strict';

    angular
        .module('awizApp')
        .directive('sortIcon', function () {
            return {
                restrict: 'AE',
                scope: {
                    column: '=',
                    predicate: '=',
                    ascending: '='
                },
                replace: true,
                template: '<span class="glyphicon " ng-class="{ \'glyphicon-sort\' : column !== predicate, \'glyphicon-sort-by-attributes\': predicate === column && ascending, \'glyphicon-sort-by-attributes-alt\': predicate === column && !ascending }"></span>'
            };
        });

})();
