(function () {
    'use strict';

    angular
        .module('awizApp')
        .factory('stateHandler', function ($rootScope, $state, translationHandler, Auth, Principal, ENV, VERSION, $transitions) {
            return {
                initialize: initialize
            };

            function initialize() {
                $transitions.onBefore({}, function (transition) {
                    if (transition.from() !== transition.to()) {
                        $rootScope.globalStateIsLoading = true;
                    }

                    var params = transition.to().data;
                    if (params && params.authorities && params.authorities.length > 0) {
                        if (Principal.isIdentityResolved()) {
                            return afterIdentityResolved();
                        } else {
                            return Auth.authorize().then(afterIdentityResolved);
                        }
                    }

                    function afterIdentityResolved() {
                        var hasRequiredAuthority = Principal.hasAnyAuthority(params.authorities);
                        if (hasRequiredAuthority === false) {
                            if (Principal.isAuthenticated()) {
                                return transition.router.stateService.target('accessdenied', undefined);
                            } else {
                                return transition.router.stateService.target('login', undefined, {location: false});
                            }
                        }
                    }
                });

                $transitions.onSuccess({}, function (transition) {
                    var titleKey = 'global.title';
                    var params = transition.to().data;
                    if (params && params.pageTitle) {
                        titleKey = params.pageTitle;
                        translationHandler.updateTitle(titleKey);
                    }

                    if (transition.from() !== transition.to()) {
                        $rootScope.globalStateIsLoading = false;
                    }
                });

                $transitions.onError({}, function (transition) {
                    if (transition.from() !== transition.to()) {
                        $rootScope.globalStateIsLoading = false;
                    }
                });

                $rootScope.ENV = ENV;
                $rootScope.VERSION = VERSION;
            }
        });

})();
