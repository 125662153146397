(function () {
    'use strict';

    angular
        .module('awizApp', [
            'ngStorage',
            'tmh.dynamicLocale',
            'pascalprecht.translate',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            //'ngTouch',
            'infinite-scroll',
            'angular-loading-bar',
            'selectionModel',
            'toaster',
            'ae-datetimepicker',
            'moment-picker',
            'xeditable',
            'amo.multiselect',
            'daterangepicker',
            'angular.filter',
            'ngSanitize',
            'checklist-model',
            'ui.calendar',
            'angularMoment',
            'angularPromiseButtons',
            'nya.bootstrap.select',
            'ngLetterAvatar',
            'angularDurationFormat',
            'angular-timezone-select'
        ])
        .config(function (angularPromiseButtonsProvider, cfpLoadingBarProvider, $uibModalProvider) {
            cfpLoadingBarProvider.latencyThreshold = 500;
            angularPromiseButtonsProvider.extendConfig({
                spinnerTpl: ''
            });
            $uibModalProvider.options.animation = false;
        })
        .run(function (stateHandler, translationHandler, editableOptions, editableThemes) {
            stateHandler.initialize();
            translationHandler.initialize();
            editableThemes.bs3.buttonsClass = 'btn-sm';
            editableOptions.theme = 'bs3';
        })
        .run(function($trace, ENV) {
            if(ENV === "dev"){
                $trace.enable('TRANSITION');
            }
        });

})();
