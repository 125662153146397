(function () {
    'use strict';

    angular
        .module('awizApp')
        .factory('FastEntry', function ($resource) {
            return $resource('api/fast-entry/:id', {id: '@id'}, {
                'query': {method: 'GET', isArray: true},
                'update': {method: 'PUT'},
                'toggleDone': {method: 'POST', url: 'api/fast-entry/:id/toggle-done'},
                'changeOrder': {method: 'POST', url: 'api/fast-entry/:id/change-order'}
            });
        })
        .directive('todoEscape', function () {
            var ESCAPE_KEY = 27;

            return function (scope, elem, attrs) {
                elem.bind('keydown', function (event) {
                    if (event.keyCode === ESCAPE_KEY) {
                        scope.$apply(attrs.todoEscape);
                    }
                });

                scope.$on('$destroy', function () {
                    elem.unbind('keydown');
                });
            };
        })
        .directive('todoFocus', function todoFocus($timeout) {
            return function (scope, elem, attrs) {
                scope.$watch(attrs.todoFocus, function (newVal) {
                    if (newVal) {
                        $timeout(function () {
                            elem[0].focus();
                        }, 0, false);
                    }
                });
            };
        })
        .directive('fastEntriesWidget', function ($rootScope, $state, $interval, $http, FastEntry) {
            return {
                restrict: 'AE',
                replace: true,
                scope: {},
                templateUrl: "app/entities/entry-reservation/widget-fast-entries/entries.html",
                link: function (scope, $element, $attrs) {

                    scope.page = 0;
                    scope.pageSize = 5;
                    scope.selectedDate = moment();

                    scope.reloadData = reloadData;
                    scope.next = function () {
                        scope.selectedDate.add(1, "d");
                        reloadData();
                    };
                    scope.previous = function () {
                        scope.selectedDate.subtract(1, "d");
                        reloadData();
                    };
                    scope.show = function (id) {
                        EntryReservationService.show(id);
                    };
                    scope.edit = function (item) {
                        scope.editedEntry = item;
                        scope.originalEntry = angular.extend({}, item);
                    };
                    scope.saveEdited = function (item, event) {
                        if (event === 'blur' && scope.saveEvent === 'submit') {
                            scope.saveEvent = null;
                            return;
                        }

                        scope.saveEvent = event;

                        if (scope.reverted) {
                            scope.reverted = null;
                            return;
                        }

                        FastEntry.update({id: item.id, content: item.content, date: scope.selectedDate.local().toDate()}).$promise.then(function () {
                            delete scope.originalEntry;
                            delete scope.editedEntry;
                            reloadData();
                        });
                    };
                    scope.toggleDone = function (item) {
                        FastEntry.toggleDone({id: item.id, done: item.done}).$promise.then(reloadData);
                    };
                    scope.moveUp = function (item) {
                        FastEntry.changeOrder({id: item.id, action: 'UP'}).$promise.then(reloadData);
                    };
                    scope.moveDown = function (item) {
                        FastEntry.changeOrder({id: item.id, action: 'DOWN'}).$promise.then(reloadData);
                    };
                    scope.delete = function (item) {
                        FastEntry.delete({id: item.id}).$promise.then(reloadData);
                    };
                    scope.revertEntry = function (item) {
                        scope.items[scope.items.indexOf(item)] = scope.originalEntry;
                        scope.originalEntry = null;
                        scope.editedEntry = null;
                        scope.reverted = true;
                    };

                    scope.addFastEntry = function () {
                        FastEntry.save({content: scope.newEntryContent, date: scope.selectedDate.local().toDate()}).$promise.then(function () {
                            delete scope.newEntryContent;
                            reloadData();
                        });
                    };

                    function reloadData() {
                        FastEntry.query({page: scope.page, size: scope.pageSize, date: scope.selectedDate.local().toDate()}, onSuccess, onError);

                        function onSuccess(response, headers) {
                            scope.items = response;
                            scope.itemsCount = Number(headers('X-Total-Count'));
                        }

                        function onError() {
                            scope.items = [];
                            scope.itemsCount = 0;
                        }
                    }

                    reloadData();

                    scope.$on('entry:updated', function () {
                        reloadData();
                    });

                    scope.changeFilter = function (filter) {
                        scope.selectedFilter = filter;
                        if (filter === 'all') {
                            scope.doneFilter = {}
                        } else if (filter === 'done') {
                            scope.doneFilter = {done: true}
                        } else if (filter === 'undone') {
                            scope.doneFilter = {done: false}
                        } else {
                            scope.doneFilter = {done: false}
                        }
                    };
                    scope.changeFilter('undone');

                    var intervalPromise;
                    function initAutoRefresh() {
                        $interval.cancel(intervalPromise);
                        reloadData();
                        intervalPromise = $interval(initAutoRefresh, 30000);
                    }

                    initAutoRefresh();
                    scope.$on('$destroy', function () {
                        $interval.cancel(intervalPromise);
                    });
                }
            };
        })
    ;

})();
