(function () {
    'use strict';

    angular
        .module('awizApp')
        .factory('Register', function ($resource) {
            return $resource('api/register', {}, {});
        });

})();
