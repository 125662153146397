(function () {
    'use strict';

    angular
        .module('awizApp')
        .controller('EntryReservationAddController', function ($timeout, $rootScope, $scope, $stateParams, $uibModalInstance, EntryReservation, ParcelType, params, Forwarder, Slot, Principal) {
            var vm = this;

            Slot.get({id: params.slotId}).$promise.then(function (slot) {
                Principal.isCompanyWorker(slot.customer.id).then(function (isCompanyWorker) {
                    vm.isSlotOwner = isCompanyWorker;
                });
            });

            vm.actionTypes = ['LOADING', 'UNLOADING'];

            ParcelType.forSlot({slotId: params.slotId}).$promise.then(function (data) {
                vm.parcelTypes = data;
            });

            vm.item = angular.extend(params);
            vm.item.trailer = false;
            vm.item.actionType = 'LOADING';

            vm.save = function () {
                return EntryReservation.save(vm.item).$promise
                    .then(function (result) {
                        $rootScope.$broadcast('entry:updated');
                        $uibModalInstance.close(result);
                    });
            };

            vm.close = function () {
                $uibModalInstance.dismiss('cancel');
            };

            vm.calculateEndTime = function () {
                if (!vm.item.parcelType || !vm.item.parcelQuantity) {
                    delete vm.calculatedEndTime;
                    return;
                }

                var processingTime = vm.item.parcelType.processingTimeInMin * vm.item.parcelQuantity;
                vm.calculatedEndTime = moment(vm.item.startTime).add(processingTime, 'm');
                vm.processingTime = processingTime;
            };

            vm.onTrailerChange = function () {
                delete vm.item.trailerRegistrationNumber;
            };

            vm.setShipper = function () {
                vm.shipperSelectable = true;
                Forwarder.autocomplete().$promise.then(function (items) {
                    vm.shippers = items;
                });
            };
        });

})();
