(function() {
    'use strict';

    angular
        .module('awizApp')
        .component('home', {
            templateUrl: 'app/home/home.html',
            controller: function (Principal) {
                this.hasSlots = Principal.hasSlots;
            }
        })
        .config(function ($stateProvider) {
            $stateProvider.state('home', {
                parent: 'app',
                url: '/',
                data: {
                    authorities: [ 'ROLE_USER' ]
                },
                views: {
                    'content@': 'home'
                }
            });
        });

})();
