(function () {
    'use strict';

    angular
        .module('awizApp')
        .controller('EntryReservationEditController', function ($timeout, $rootScope, $scope, $uibModalInstance, EntryReservation, ParcelType, entryId) {
            var vm = this;

            vm.actionTypes = ['LOADING', 'UNLOADING'];

            EntryReservation.get({id: entryId}).$promise
                .then(function (data) {
                    vm.item = data;
                    vm.calculatedEndTime = data.endTime;
                    vm.item.trailer = !!data.trailerRegistrationNumber;
                    loadParcelTypes(data.slot.id);
                });

            vm.save = function () {
                return EntryReservation.update(vm.item).$promise
                    .then(function (data) {
                        $rootScope.$broadcast('entry:updated');
                        $uibModalInstance.close(data);
                    });
            };

            function loadParcelTypes(slotId) {
                ParcelType.forSlot({slotId: slotId}).$promise.then(function (data) {
                    vm.parcelTypes = data;
                });
            }

            vm.close = function () {
                $uibModalInstance.dismiss('cancel');
            };

            vm.calculateEndTime = function () {
                if (!vm.item.parcelType || !vm.item.parcelQuantity) {
                    delete vm.calculatedEndTime;
                    return;
                }

                var processingTime = vm.item.parcelType.processingTimeInMin * vm.item.parcelQuantity;
                vm.processingTime = processingTime;
                vm.calculatedEndTime = moment(vm.item.startTime).add(processingTime, 'm');
            };

            vm.onTrailerChange = function () {
                delete vm.item.trailerRegistrationNumber;
            };
        });

})();
