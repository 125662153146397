(function() {
    'use strict';

    angular
        .module('awizApp')
        .controller('ResetFinishController', function ($stateParams, Auth, $translate) {
            var vm = this;
            Auth.logout();

            if(angular.isDefined($stateParams.lang)){
                $translate.use($stateParams.lang);
            }

            vm.keyMissing = angular.isUndefined($stateParams.key);
            vm.confirmPassword = null;
            vm.doNotMatch = null;
            vm.error = null;
            vm.finishReset = finishReset;
            vm.resetAccount = {};
            vm.success = null;

            function finishReset() {
                vm.doNotMatch = null;
                vm.error = null;
                vm.success = null;
                if (vm.resetAccount.password !== vm.confirmPassword) {
                    vm.doNotMatch = 'ERROR';
                } else {
                    Auth.resetPasswordFinish({key: $stateParams.key, newPassword: vm.resetAccount.password}).then(function () {
                        vm.success = 'OK';
                    }).catch(function () {
                        vm.success = null;
                        vm.error = 'ERROR';
                    });
                }
            }
        });


})();
