(function() {
    'use strict';

    angular
        .module('awizApp')
        .controller('CustomerDialogController', function ($timeout, $scope, id, $uibModalInstance, Customer) {
            var vm = this;

            Customer.get({id: id}).$promise
                .then(function (data) {
                    vm.customerAccount = data;
                });

            vm.save = function () {
                var params = {
                    id: vm.customerAccount.customer.id,
                    slots: vm.customerAccount.customer.slots
                };
                return Customer.update(params, onSaveSuccess, onSaveError).$promise;

                function onSaveSuccess(result) {
                    $uibModalInstance.close(result);
                }

                function onSaveError() {}
            };

            vm.close = function() {
                $uibModalInstance.dismiss('cancel');
            };
        });

})();
