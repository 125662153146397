(function() {
    'use strict';

    angular
        .module('awizApp')
        .config(function ($stateProvider) {
            $stateProvider.state('entity', {
                abstract: true,
                parent: 'app'
            });
        });

})();
