(function() {
    'use strict';

    angular
        .module('awizApp')
        .controller('RequestResetController', function (Auth) {
            var vm = this;

            vm.error = null;
            vm.errorEmailNotExists = null;
            vm.resetAccount = {};
            vm.success = null;

            vm.requestReset = function () {
                vm.error = null;
                vm.errorEmailNotExists = null;

                Auth.resetPasswordInit(vm.resetAccount.email).then(function () {
                    vm.success = 'OK';
                }).catch(function () {
                    vm.success = null;
                    vm.error = 'ERROR';
                });
            };

        });

})();
