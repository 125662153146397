(function() {
    'use strict';

    angular
        .module('awizApp')
        .directive('auditInfo', function () {
            return {
                scope: {
                    audit: "="
                },
                restrict: 'AE',
                templateUrl: 'app/components/audit-info/audit-info.html',
                link: function(scope, $element, $attrs) {

                }
            };
        });
})();
