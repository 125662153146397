(function () {
    'use strict';

    angular
        .module('awizApp')
        .controller('SlotScheduleDialogController', function ($scope, item, $uibModalInstance, SlotSchedule) {
            var vm = this;

            vm.dayNames = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
            vm.item = item;

            vm.save = function () {
                if (vm.item.id !== null) {
                    SlotSchedule.update(vm.item).$promise.then(onSave);
                } else {
                    SlotSchedule.save(vm.item).$promise.then(onSave);
                }

                function onSave(result) {
                    $scope.$emit('slot:scheduleUpdated', result);
                    $uibModalInstance.close(result);
                }
            };

            vm.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            };

            vm.addPeriod = function () {
                vm.item.periods.push({});
            };

            vm.removePeriod = function (idx) {
                vm.item.periods.splice(idx, 1);
            };
        });

})();
