(function () {
    'use strict';

    angular
        .module('awizApp')
        .config(function (uibPagerConfig, paginationConstants) {
            uibPagerConfig.itemsPerPage = paginationConstants.itemsPerPage;
            uibPagerConfig.previousText = '«';
            uibPagerConfig.nextText = '»';
        });

})();
