(function() {
    'use strict';

    angular
        .module('awizApp')
        .directive('mobileFilters', function (MobileTableFilters) {
            return {
                scope: {},
                link: function (scope, $element) {
                    if(MobileTableFilters.isHidden()){
                        $element.addClass('hidden-xs');
                    }

                    MobileTableFilters.onToggle(function (toggled) {
                        if(toggled){
                            $element.removeClass('hidden-xs');
                        }
                        else{
                            $element.addClass('hidden-xs');
                        }
                    });
                }
            };
        })
        .component('tableFiltersSwitch', {
            controller: function(MobileTableFilters){
                this.toggleMobileFilters = function (){
                    MobileTableFilters.toggle();
                };
            },
            template: '<div class="form-group input-group-sm visible-xs filter-radio-button">\n' +
                '            <button type="button" class="btn btn-default btn-xs" ng-click="$ctrl.toggleMobileFilters()">\n' +
                '                <i class="fas fa-filter"></i>\n' +
                '            </button>\n' +
                '        </div>'
        })
        .provider('MobileTableFilters', function () {
            this.$get = function() {
                return {
                    show: false,
                    onToggleCallback: angular.noop,
                    init: function () {
                        this.show = false;
                        this.onToggleCallback(this.show);
                        return this;
                    },
                    toggle: function() {
                        this.show = !this.show;
                        this.onToggleCallback(this.show);
                    },
                    isHidden: function () {
                        return !this.show;
                    },
                    onToggle: function (callbackFn) {
                        if (callbackFn instanceof Function){
                            this.onToggleCallback = callbackFn;
                        }
                    }
                };
            };
        })
        .component('pagination', {
            bindings: {
                itemsCount: "<",
                size: "<",
                page: "<"
            },
            template: "<div class=\"text-center\">\n" +
                "    <div uib-pagination total-items=\"$ctrl.itemsCount\" items-per-page=\"$ctrl.size\" ng-model=\"$ctrl.page\" max-size=\"7\" class=\"pagination-sm\" ng-change=\"$ctrl.onPageChange()\" boundary-link-numbers=\"true\" ng-if=\"$ctrl.itemsCount && $ctrl.itemsCount > 0\"></div>\n" +
                "</div>",
            controller: function($state, $stateParams){
                this.onPageChange = function () {
                    $stateParams.page = this.page;
                    $state.transitionTo($state.current, $stateParams);
                };
            }
        })
        ;
})();
