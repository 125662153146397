(function() {
    'use strict';

    angular
        .module('awizApp')
        .config(function ($stateProvider) {
            $stateProvider.state('finishReset', {
                parent: 'account',
                url: '/reset/finish?key&lang',
                data: {
                    authorities: [],
                    pageTitle: 'reset.finish.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/reset/finish/reset.finish.html',
                        controller: 'ResetFinishController',
                        controllerAs: 'vm'
                    }
                }
            });
        });

})();
