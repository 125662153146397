(function () {
    'use strict';

    angular
        .module('awizApp')
        .component('slots', {
            bindings: {
                filters: '=',
                page: '='
            },
            templateUrl: 'app/entities/slot/slots.html',
            controller: function ($state, Slot, ConfirmDialogService) {
                this.submit = function () {
                    $state.go($state.current, this.filters);
                };
                this.delete = function (item) {
                    return ConfirmDialogService
                        .open({
                            messageTranslated: {
                                key: "slot.label.delete",
                                params: {param: item.name}
                            },
                            confirmPromise: function () {
                                return Slot.delete({id: item.id}).$promise;
                            }
                        })
                        .then(function () {
                            $state.reload();
                        });
                };
            }
        })
        .component('slotSchedules', {
            bindings: {
                filters: '=',
                page: '=',
                slot: '<'
            },
            templateUrl: 'app/entities/slot/schedules.html',
            controller: function ($state, SlotSchedule, ConfirmDialogService) {
                this.submit = function () {
                    $state.go($state.current, this.filters);
                };
                this.delete = function (item) {
                    return ConfirmDialogService
                        .open({
                            messageTranslated: {
                                key: "entity.delete.question",
                                params: {param: item.startTime}
                            },
                            confirmPromise: function () {
                                return SlotSchedule.delete({id: item.id}).$promise;
                            }
                        })
                        .then(function () {
                            $state.reload();
                        });
                };
            }
        })
        .config(function ($stateProvider) {
            $stateProvider
                .state('slot', {
                    parent: 'entity',
                    url: '/slot?page&sort&search&size',
                    data: {
                        authorities: ['ROLE_USER']
                    },
                    views: {
                        'content@': 'slots'
                    },
                    params: {
                        page: {
                            value: '1',
                            squash: true
                        },
                        size: {
                            value: '50',
                            squash: true
                        },
                        sort: {
                            value: 'name,asc',
                            squash: true
                        },
                        search: null
                    },
                    resolve: {
                        filters: function ($transition$) {
                            return angular.copy($transition$.params());
                        },
                        page: function (Slot, $q, $stateParams) {
                            var deferred = $q.defer();
                            var queryParams = angular.copy($stateParams);
                            queryParams.page -= 1;
                            Slot.query(queryParams, onSuccess, onError);

                            function onSuccess(response, headers) {
                                deferred.resolve({
                                    items: response,
                                    itemsCount: headers('X-Total-Count')
                                });
                            }

                            function onError() {
                                deferred.reject();
                            }

                            return deferred.promise;
                        }
                    }
                })
                .state('slot.create', {
                    data: {
                        authorities: ['ROLE_USER']
                    },
                    onEnter: function (ModalUtil, $uibModal, $transition$) {
                        $uibModal.open({
                            templateUrl: 'app/entities/slot/dialog.html',
                            controller: 'SlotDialogController',
                            controllerAs: 'vm',
                            backdrop: 'static',
                            size: 'md',
                            resolve: {
                                item: function () {
                                    return {
                                        id: null,
                                        active: true,
                                        timezone: moment.tz.guess()
                                    };
                                }
                            }
                        }).result
                            .then(function () {
                                ModalUtil.defaultThen($transition$.from(), $transition$.params('from'));
                            })
                            .catch(function () {
                                ModalUtil.defaultCatch($transition$.from(), $transition$.params('from'));
                            });
                    },
                    onExit: function ($uibModalStack) {
                        $uibModalStack.dismissAll();
                    }
                })
                .state('slot.edit', {
                    data: {
                        authorities: ['ROLE_USER']
                    },
                    params: {
                        id: null
                    },
                    onEnter: function ($stateParams, ModalUtil, $transition$, $uibModal, Slot) {
                        $uibModal.open({
                            templateUrl: 'app/entities/slot/dialog.html',
                            controller: 'SlotDialogController',
                            controllerAs: 'vm',
                            backdrop: 'static',
                            size: 'md',
                            resolve: {
                                item: function () {
                                    return Slot.get({id: $stateParams.id}).$promise;
                                }
                            }
                        }).result
                            .then(function () {
                                ModalUtil.defaultThen($transition$.from(), $transition$.params('from'));
                            })
                            .catch(function () {
                                ModalUtil.defaultCatch($transition$.from(), $transition$.params('from'));
                            });
                    },
                    onExit: function ($uibModalStack) {
                        $uibModalStack.dismissAll();
                    }
                })
                .state('slotSchedule', {
                    parent: 'entity',
                    url: '/slot/{slotId}/schedule?page&sort&search&size',
                    data: {
                        authorities: ['ROLE_USER']
                    },
                    views: {
                        'content@': 'slotSchedules'
                    },
                    params: {
                        page: {
                            value: '1',
                            squash: true
                        },
                        size: {
                            value: '50',
                            squash: true
                        },
                        sort: {
                            value: 'info.startTime,desc',
                            squash: true
                        },
                        search: null
                    },
                    resolve: {
                        filters: function ($transition$) {
                            return angular.copy($transition$.params());
                        },
                        page: function (SlotSchedule, $q, $stateParams) {
                            var deferred = $q.defer();
                            var queryParams = angular.copy($stateParams);
                            queryParams.page -= 1;
                            queryParams.id = $stateParams.slotId;
                            SlotSchedule.schedule(queryParams, onSuccess, onError);

                            function onSuccess(response, headers) {
                                deferred.resolve({
                                    items: response,
                                    itemsCount: headers('X-Total-Count')
                                });
                            }

                            function onError() {
                                deferred.reject();
                            }

                            return deferred.promise;
                        },
                        slot: function (Slot, $stateParams) {
                            return Slot.get({id: $stateParams.slotId}).$promise;
                        }
                    }
                })
                .state('slotSchedule.create', {
                    data: {
                        authorities: ['ROLE_USER']
                    },
                    params: {
                        slotId: null
                    },
                    onEnter: function ($stateParams, $transition$, ModalUtil, $uibModal) {
                        $uibModal.open({
                            templateUrl: 'app/entities/slot/schedule-dialog.html',
                            controller: 'SlotScheduleDialogController',
                            controllerAs: 'vm',
                            backdrop: 'static',
                            size: 'lg',
                            resolve: {
                                item: function () {
                                    return {
                                        id: null,
                                        slotId: $stateParams.slotId,
                                        periods: [
                                            {}
                                        ]
                                    };
                                }
                            }
                        }).result.then(function () {
                            ModalUtil.defaultThen($transition$.from(), $transition$.params('from'));
                        }).catch(function () {
                            ModalUtil.defaultCatch($transition$.from(), $transition$.params('from'));
                        });
                    },
                    onExit: function ($uibModalStack) {
                        $uibModalStack.dismissAll();
                    }
                })
                .state('slotSchedule.duplicate', {
                    data: {
                        authorities: ['ROLE_USER']
                    },
                    params: {
                        duplicatedId: null
                    },
                    onEnter: function ($stateParams, $transition$, ModalUtil, $uibModal, SlotSchedule) {
                        $uibModal.open({
                            templateUrl: 'app/entities/slot/schedule-dialog.html',
                            controller: 'SlotScheduleDialogController',
                            controllerAs: 'vm',
                            backdrop: 'static',
                            size: 'lg',
                            resolve: {
                                item: function () {
                                    return SlotSchedule.get({id: $stateParams.duplicatedId}).$promise.then(function (result) {
                                        return {
                                            id: null,
                                            slotId: result.slotId,
                                            notes: result.notes,
                                            periods: result.periods
                                        };
                                    });
                                }
                            }
                        }).result.then(function () {
                            ModalUtil.defaultThen($transition$.from(), $transition$.params('from'));
                        }).catch(function () {
                            ModalUtil.defaultCatch($transition$.from(), $transition$.params('from'));
                        });
                    },
                    onExit: function ($uibModalStack) {
                        $uibModalStack.dismissAll();
                    }
                })
                .state('slotSchedule.edit', {
                    data: {
                        authorities: ['ROLE_USER']
                    },
                    params: {
                        scheduleId: null
                    },
                    onEnter: function ($stateParams, $transition$, ModalUtil, $uibModal, SlotSchedule) {
                        $uibModal.open({
                            templateUrl: 'app/entities/slot/schedule-dialog.html',
                            controller: 'SlotScheduleDialogController',
                            controllerAs: 'vm',
                            backdrop: 'static',
                            size: 'lg',
                            resolve: {
                                item: function () {
                                    return SlotSchedule.get({id: $stateParams.scheduleId}).$promise;
                                }
                            }
                        }).result.then(function () {
                            ModalUtil.defaultThen($transition$.from(), $transition$.params('from'));
                        }).catch(function () {
                            ModalUtil.defaultCatch($transition$.from(), $transition$.params('from'));
                        });
                    },
                    onExit: function ($uibModalStack) {
                        $uibModalStack.dismissAll();
                    }
                })
            ;
        });

})();
