(function() {
    'use strict';

    angular
        .module('awizApp')
        .factory('translationHandler', translationHandler);

    translationHandler.$inject = ['$rootScope', '$window', '$state', '$translate', 'amMoment', 'tmhDynamicLocale', 'Principal'];

    function translationHandler($rootScope, $window, $state, $translate, amMoment, tmhDynamicLocale, Principal) {
        return {
            initialize: initialize,
            updateTitle: updateTitle
        };

        function initialize() {
            // if the current translation changes, update the window title
            var translateChangeSuccess = $rootScope.$on('$translateChangeSuccess', function(e, languageObj) {
                updateTitle();
                changeLocaleDependencies(languageObj.language);
            });

            $rootScope.$watch(function() {
                return Principal.isAuthenticated();
            }, function() {
                Principal.identity().then(function (account) {
                    if (account!== null) {
                        $translate.use(account.language);
                    }
                });
            });

            $rootScope.$on('$destroy', function () {
                if(angular.isDefined(translateChangeSuccess) && translateChangeSuccess !== null){
                    translateChangeSuccess();
                }
            });
        }

        function changeLocaleDependencies(languageKey) {
            if(languageKey){
                amMoment.changeLocale(languageKey, {});
                tmhDynamicLocale.set(languageKey);
            }
        }

        // update the window title using params in the following
        // precendence
        // 1. titleKey parameter
        // 2. $state.$current.data.pageTitle (current state page title)
        // 3. 'global.title'
        function updateTitle(titleKey) {
            if (!titleKey && $state.$current.data && $state.$current.data.pageTitle) {
                titleKey = $state.$current.data.pageTitle;
            }
            $translate(titleKey || 'global.title').then(function (title) {
                $window.document.title = title;
            });
        }
    }
})();
